import {
  ADD_TOXIC_CATEGORY_FAILLED,
  ADD_TOXIC_CATEGORY_REQUEST,
  ADD_TOXIC_CATEGORY_SUCCESS,
  DELETE_TOXIC_CATEGORY_FAILLED,
  DELETE_TOXIC_CATEGORY_REQUEST,
  DELETE_TOXIC_CATEGORY_SUCCESS,
  GET_TOXIC_CATEGORIES_FAILLED,
  GET_TOXIC_CATEGORIES_REQUEST,
  GET_TOXIC_CATEGORIES_SUCCESS,
  UPDATE_TOXIC_CATEGORY_FAILLED,
  UPDATE_TOXIC_CATEGORY_REQUEST,
  UPDATE_TOXIC_CATEGORY_SUCCESS,
} from "../types/types";

const initialeState = {
  toxicCategories: [],
  toxicCategoriesLoading: false,
  toxicCategoryUpdateLoading: false,
};

const toxicCategoryReducer = (state = initialeState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TOXIC_CATEGORIES_REQUEST:
      return {
        ...state,
        toxicCategoriesLoading: true,
      };
    case GET_TOXIC_CATEGORIES_SUCCESS:
      return {
        ...state,
        toxicCategories: payload,
        toxicCategoriesLoading: false,
      };
    case GET_TOXIC_CATEGORIES_FAILLED:
      return {
        ...state,
        toxicCategoriesLoading: false,
      };
    case ADD_TOXIC_CATEGORY_REQUEST:
      return {
        ...state,
        toxicCategoryUpdateLoading: true,
      };
    case ADD_TOXIC_CATEGORY_SUCCESS:
      return {
        ...state,
        toxicCategoryUpdateLoading: false,
      };
    case ADD_TOXIC_CATEGORY_FAILLED:
      return {
        ...state,
        toxicCategoryUpdateLoading: false,
      };
    case UPDATE_TOXIC_CATEGORY_REQUEST:
      return {
        ...state,
        toxicCategoryUpdateLoading: true,
      };
    case UPDATE_TOXIC_CATEGORY_SUCCESS:
      return {
        ...state,
        toxicCategoryUpdateLoading: false,
      };
    case UPDATE_TOXIC_CATEGORY_FAILLED:
      return {
        ...state,
        toxicCategoryUpdateLoading: false,
      };
    case DELETE_TOXIC_CATEGORY_REQUEST:
      return {
        ...state,
        toxicCategoryUpdateLoading: true,
      };
    case DELETE_TOXIC_CATEGORY_SUCCESS:
      return {
        ...state,
        toxicCategoryUpdateLoading: false,
      };
    case DELETE_TOXIC_CATEGORY_FAILLED:
      return {
        ...state,
        toxicCategoryUpdateLoading: false,
      };
    default:
      return state;
  }
};

export default toxicCategoryReducer;
