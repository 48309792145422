import { Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteToxic } from "../../redux/actions/toxicActions";
import "./styles.css";

function ToxicDeleteModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();

  const onDeleteToxic = () => {
    deleteToxic(token, toggleModal.toxic._id, closeModal, dispatch);
  };

  return (
    <Modal
      title={`Supprimer l'aliment toxique ${toggleModal.toxic.name}`}
      open={true}
      onCancel={closeModal}
      footer={() => null}
      className="category_modal_container"
    >
      <div>
        <button onClick={onDeleteToxic}>Oui</button>
        <button onClick={closeModal}>Non</button>
      </div>
    </Modal>
  );
}

export default ToxicDeleteModal;
