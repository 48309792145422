import {
  ADD_DIET_FAILLED,
  ADD_DIET_REQUEST,
  ADD_DIET_SUCCESS,
  DELETE_DIET_FAILLED,
  DELETE_DIET_REQUEST,
  DELETE_DIET_SUCCESS,
  GET_DIETS_FAILLED,
  GET_DIETS_REQUEST,
  GET_DIETS_SUCCESS,
  UPDATE_DIET_FAILLED,
  UPDATE_DIET_REQUEST,
  UPDATE_DIET_SUCCESS,
} from "../types/types";

const initialeState = {
  diets: [],
  dietsLoading: false,
  dietUpdateLoading: false,
};

const dietReducer = (state = initialeState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DIETS_REQUEST:
      return {
        ...state,
        dietsLoading: true,
      };
    case GET_DIETS_SUCCESS:
      return {
        ...state,
        diets: payload,
        dietsLoading: false,
      };
    case GET_DIETS_FAILLED:
      return {
        ...state,
        dietsLoading: false,
      };
    case ADD_DIET_REQUEST:
      return {
        ...state,
        dietUpdateLoading: true,
      };
    case ADD_DIET_SUCCESS:
      return {
        ...state,
        dietUpdateLoading: false,
      };
    case ADD_DIET_FAILLED:
      return {
        ...state,
        dietUpdateLoading: false,
      };
    case UPDATE_DIET_REQUEST:
      return {
        ...state,
        dietUpdateLoading: true,
      };
    case UPDATE_DIET_SUCCESS:
      return {
        ...state,
        dietUpdateLoading: false,
      };
    case UPDATE_DIET_FAILLED:
      return {
        ...state,
        dietUpdateLoading: false,
      };
    case DELETE_DIET_REQUEST:
      return {
        ...state,
        dietUpdateLoading: true,
      };
    case DELETE_DIET_SUCCESS:
      return {
        ...state,
        dietUpdateLoading: false,
      };
    case DELETE_DIET_FAILLED:
      return {
        ...state,
        dietUpdateLoading: false,
      };
    default:
      return state;
  }
};

export default dietReducer;
