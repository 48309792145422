import {
  ADD_BREED_FAILLED,
  ADD_BREED_REQUEST,
  ADD_BREED_SUCCESS,
  DELETE_BREED_FAILLED,
  DELETE_BREED_REQUEST,
  DELETE_BREED_SUCCESS,
  GET_BREEDS_FAILLED,
  GET_BREEDS_REQUEST,
  GET_BREEDS_SUCCESS,
  UPDATE_BREED_FAILLED,
  UPDATE_BREED_REQUEST,
  UPDATE_BREED_SUCCESS,
} from "../types/types";

const initialeState = {
  breeds: [],
  breedsLoading: false,
  breedUpdateLoading: false,
};

const breedReducer = (state = initialeState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_BREEDS_REQUEST:
      return {
        ...state,
        breedsLoading: true,
      };
    case GET_BREEDS_SUCCESS:
      return {
        ...state,
        breeds: payload,
        breedsLoading: false,
      };
    case GET_BREEDS_FAILLED:
      return {
        ...state,
        breedsLoading: false,
      };
    case ADD_BREED_REQUEST:
      return {
        ...state,
        breedUpdateLoading: true,
      };
    case ADD_BREED_SUCCESS:
      return {
        ...state,
        breedUpdateLoading: false,
      };
    case ADD_BREED_FAILLED:
      return {
        ...state,
        breedUpdateLoading: false,
      };
    case UPDATE_BREED_REQUEST:
      return {
        ...state,
        breedUpdateLoading: true,
      };
    case UPDATE_BREED_SUCCESS:
      return {
        ...state,
        breedUpdateLoading: false,
      };
    case UPDATE_BREED_FAILLED:
      return {
        ...state,
        breedUpdateLoading: false,
      };
    case DELETE_BREED_REQUEST:
      return {
        ...state,
        breedUpdateLoading: true,
      };
    case DELETE_BREED_SUCCESS:
      return {
        ...state,
        breedUpdateLoading: false,
      };
    case DELETE_BREED_FAILLED:
      return {
        ...state,
        breedUpdateLoading: false,
      };
    default:
      return state;
  }
};

export default breedReducer;
