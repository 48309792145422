import { combineReducers } from "redux";
import adminReducer from "./adminReducer";
import animalReducer from "./animalReducer";
import brandReducer from "./brandReducer";
import breedReducer from "./breedReducer";
import dietReducer from "./dietReducer";
import toxicCategoryReducer from "./toxicCategoryReducer";
import toxicReducer from "./toxicReducer";
import userReducer from "./userReducer";
import vaccineReducer from "./vaccineReducer";

const rootReducer = combineReducers({
  adminReducer: adminReducer,
  animalReducer: animalReducer,
  breedReducer: breedReducer,
  vaccineReducer: vaccineReducer,
  dietReducer: dietReducer,
  brandReducer: brandReducer,
  toxicCategoryReducer: toxicCategoryReducer,
  toxicReducer: toxicReducer,
  userReducer: userReducer,
});

export default rootReducer;
