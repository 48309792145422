import { Button, Form, InputNumber, Modal, Select, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addHealthBook,
  getHealthBook,
  updateHealthBook,
} from "../../redux/actions/healthBookActions";
import "./styles.css";
const { Option } = Select;

function HealthBookModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { diets } = useSelector((state) => state.dietReducer);
  const { vaccines } = useSelector((state) => state.vaccineReducer);
  const { brands } = useSelector((state) => state.brandReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [sterilized, setSterilized] = useState("");
  const [allergy, setAllergy] = useState([]);
  const [vaccinesList, setVaccines] = useState([]);

  useEffect(() => {
    console.log(toggleModal);
    if (toggleModal.healthBook) {
      getHealthBook(token, toggleModal.healthBook)
        .then((res) => {
          console.log(res.data);
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            sterilized: res.data.sterilized,
            diet: res.data.diet,
            brand: res.data.brand,
            weight: res.data.weight,
          };
          setAllergy(res.data.allergy);
          setVaccines(res.data.vaccines);
          setSterilized(res.data.sterilized);
          form.setFieldsValue(fields);
        })
        .catch((err) => console.log(err));
    }
  }, [toggleModal, form, token]);

  const onAddCategory = (values) => {
    const body = {
      ...values,
    };
    console.log(body);
    if (toggleModal.healthBook) {
      updateHealthBook(
        token,
        toggleModal.healthBook,
        body,
        closeModal,
        dispatch
      );
    } else {
      addHealthBook(token, body, closeModal, dispatch);
    }
  };

  const onAnimalChange = (value) => {
    form.setFieldsValue({ animal: value });
  };

  const onBreedChange = (value) => {
    form.setFieldsValue({ breed: value });
  };

  return (
    <Modal
      title={
        toggleModal.healthBook
          ? "Modifier la catégorie de l'healthBook"
          : "Ajouter une catégorie d'healthBook"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
      className="category_modal_container"
    >
      <Form
        layout="vertical"
        onFinish={onAddCategory}
        form={form}
        initialValues={{
          image: "",
          name: "",
          order: "",
          active: true,
        }}
      >
        <Form.Item label="Allergies">
          {allergy.map((all) => (
            <div>{all}</div>
          ))}
        </Form.Item>
        <Form.Item label="Vaccins">
          {vaccinesList.map((vaccine) => (
            <div
              style={{
                display: "flex",
                paddingBottom: "5px 0",
                borderBottom: "1px solid lightgray",
              }}
            >
              <div style={{ marginRight: 20 }}>
                {vaccines.find((item) => item._id === vaccine.id).name}
              </div>
              <div>{new Date(vaccine.date).toLocaleDateString("fr")}</div>
            </div>
          ))}
        </Form.Item>
        <Form.Item
          label="Diet du membre"
          name="diet"
          rules={[
            {
              required: true,
              message:
                "Veuillez ajouter le nom de la catégorie de l'healthBook!",
            },
          ]}
        >
          <Select
            placeholder="Select a option and change input text above"
            onChange={onAnimalChange}
            allowClear={false}
          >
            {diets.map((diet) => (
              <Option key={diet._id} value={diet._id}>
                {diet.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Marque de nourriture"
          name="brand"
          rules={[
            {
              required: true,
              message:
                "Veuillez ajouter le nom de la catégorie de l'healthBook!",
            },
          ]}
        >
          <Select
            placeholder="Select a option and change input text above"
            onChange={onBreedChange}
            allowClear={false}
          >
            {brands.map((brand) => (
              <Option key={brand._id} value={brand._id}>
                {brand.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Le membre est-il stérilisé"
          name="sterilized"
          rules={[
            {
              required: true,
              message:
                "Veuillez ajouter le nom de la catégorie de l'healthBook!",
            },
          ]}
        >
          <div>
            <Button
              onClick={() => setSterilized("Oui")}
              type={sterilized === "Oui" ? "primary" : "default"}
            >
              Oui
            </Button>
            <Button
              onClick={() => setSterilized("Non")}
              type={sterilized === "Non" ? "primary" : "default"}
            >
              Non
            </Button>
          </div>
        </Form.Item>
        <Form.Item
          label="Poid du membre"
          name="weight"
          rules={[
            {
              required: true,
              message:
                "Veuillez ajouter le nom de la catégorie de l'healthBook!",
            },
          ]}
        >
          <InputNumber placeholder="Chat" />
        </Form.Item>
        <Form.Item label="Active" name="active">
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default HealthBookModal;
