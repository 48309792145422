import {
  LOGIN_ADMIN_FAILLED,
  LOGIN_ADMIN_REQUEST,
  LOGIN_ADMIN_SUCCESS,
  LOGOUT_ADMIN,
} from "../types/types";

const initialeState = {
  admin: null,
  token: null,
  adminLoading: false,
};

const adminReducer = (state = initialeState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGIN_ADMIN_REQUEST:
      return {
        ...state,
        adminLoading: true,
      };
    case LOGIN_ADMIN_SUCCESS:
      localStorage.setItem("token", payload.token);
      return {
        ...state,
        adminLoading: false,
        admin: payload.admin,
        token: payload.token,
      };
    case LOGIN_ADMIN_FAILLED:
      return {
        ...state,
        adminLoading: false,
      };
    case LOGOUT_ADMIN:
      localStorage.removeItem("token");
      return {
        ...state,
        admin: null,
        token: null,
        adminLoading: false,
      };
    default:
      return state;
  }
};

export default adminReducer;
