import { ConfigProvider } from "antd";
import locale from "antd/locale/fr_FR";
import dayjs from "dayjs";
import "react-clock/dist/Clock.css";
import { Provider } from "react-redux";
import "react-time-picker/dist/TimePicker.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import store from "../src/redux/store";
import "./App.css";

import "dayjs/locale/fr";
import Navigation from "./navigation/Navigation";

dayjs.locale("fr");

function App() {
  return (
    <ConfigProvider
      locale={locale}
      theme={{
        token: {
          colorPrimary: "#c3ae6e",
        },
      }}
    >
      <Provider store={store}>
        <ToastContainer autoClose={2000} theme="colored" />
        <Navigation />
      </Provider>
    </ConfigProvider>
  );
}

export default App;
