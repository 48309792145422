import axios from "axios";
import { toast } from "react-toastify";
import {
  LOGIN_ADMIN_FAILLED,
  LOGIN_ADMIN_REQUEST,
  LOGIN_ADMIN_SUCCESS,
  LOGOUT_ADMIN,
} from "../types/types";
import { GET_ADMIN_URL, LOGIN_ADMIN_URL } from "../url/url";

export const loginAdmin = (body, dispatch) => {
  dispatch({
    type: LOGIN_ADMIN_REQUEST,
  });
  axios
    .post(LOGIN_ADMIN_URL, body)
    .then((res) => {
      toast(res.data.message, { type: "success" });
      dispatch({
        type: LOGIN_ADMIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: LOGIN_ADMIN_FAILLED,
      });
    });
};

export const getAdmin = (body, dispatch) => {
  dispatch({
    type: LOGIN_ADMIN_REQUEST,
  });
  axios
    .post(GET_ADMIN_URL, body)
    .then((res) => {
      dispatch({
        type: LOGIN_ADMIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: LOGIN_ADMIN_FAILLED,
      });
    });
};

export const logoutAdmin = (dispatch) => {
  dispatch({ type: LOGOUT_ADMIN });
};
