import axios from "axios";
import { toast } from "react-toastify";
import {
  ADD_BREED_FAILLED,
  ADD_BREED_REQUEST,
  ADD_BREED_SUCCESS,
  DELETE_BREED_FAILLED,
  DELETE_BREED_REQUEST,
  DELETE_BREED_SUCCESS,
  GET_BREEDS_FAILLED,
  GET_BREEDS_REQUEST,
  GET_BREEDS_SUCCESS,
  UPDATE_BREED_FAILLED,
  UPDATE_BREED_REQUEST,
  UPDATE_BREED_SUCCESS,
} from "../types/types";
import { BREEDS_URL } from "../url/url";

export const getBreeds = (token, dispatch) => {
  dispatch({
    type: GET_BREEDS_REQUEST,
  });
  axios
    .get(BREEDS_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_BREEDS_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_BREEDS_FAILLED,
      });
    });
};

export const getBreed = (token, id) => {
  return axios.get(`${BREEDS_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addBreed = (token, body, closeModal, dispatch) => {
  dispatch({
    type: ADD_BREED_REQUEST,
  });
  axios
    .post(BREEDS_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_BREED_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getBreeds(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: ADD_BREED_FAILLED,
      });
    });
};

export const updateBreed = (token, id, body, dispatch) => {
  dispatch({
    type: UPDATE_BREED_REQUEST,
  });
  axios
    .post(`${BREEDS_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: UPDATE_BREED_SUCCESS,
      });
      toast(res.data.message, { type: "success" });
      getBreeds(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: UPDATE_BREED_FAILLED,
      });
    });
};

export const deleteBreed = (token, id, closeModal, dispatch) => {
  dispatch({
    type: DELETE_BREED_REQUEST,
  });
  axios
    .delete(`${BREEDS_URL}/${id}`, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: DELETE_BREED_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getBreeds(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: DELETE_BREED_FAILLED,
      });
    });
};
