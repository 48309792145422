export const LOGIN_ADMIN_REQUEST = "LOGIN_ADMIN_REQUEST";
export const LOGIN_ADMIN_SUCCESS = "LOGIN_ADMIN_SUCCESS";
export const LOGIN_ADMIN_FAILLED = "LOGIN_ADMIN_FAILLED";

export const LOGOUT_ADMIN = "LOGOUT_ADMIN";

export const GET_ANIMALS_REQUEST = "GET_ANIMALS_REQUEST";
export const GET_ANIMALS_SUCCESS = "GET_ANIMALS_SUCCESS";
export const GET_ANIMALS_FAILLED = "GET_ANIMALS_FAILLED";

export const ADD_ANIMAL_IMAGE_REQUEST = "ADD_ANIMAL_IMAGE_REQUEST";
export const ADD_ANIMAL_IMAGE_SUCCESS = "ADD_ANIMAL_IMAGE_SUCCESS";
export const ADD_ANIMAL_IMAGE_FAILLED = "ADD_ANIMAL_IMAGE_FAILLED";

export const ADD_ANIMAL_REQUEST = "ADD_ANIMAL_REQUEST";
export const ADD_ANIMAL_SUCCESS = "ADD_ANIMAL_SUCCESS";
export const ADD_ANIMAL_FAILLED = "ADD_ANIMAL_FAILLED";

export const UPDATE_ANIMAL_REQUEST = "UPDATE_ANIMAL_REQUEST";
export const UPDATE_ANIMAL_SUCCESS = "UPDATE_ANIMAL_SUCCESS";
export const UPDATE_ANIMAL_FAILLED = "UPDATE_ANIMAL_FAILLED";

export const DELETE_ANIMAL_REQUEST = "DELETE_ANIMAL_REQUEST";
export const DELETE_ANIMAL_SUCCESS = "DELETE_ANIMAL_SUCCESS";
export const DELETE_ANIMAL_FAILLED = "DELETE_ANIMAL_FAILLED";

export const GET_BREEDS_REQUEST = "GET_BREEDS_REQUEST";
export const GET_BREEDS_SUCCESS = "GET_BREEDS_SUCCESS";
export const GET_BREEDS_FAILLED = "GET_BREEDS_FAILLED";

export const ADD_BREED_REQUEST = "ADD_BREED_REQUEST";
export const ADD_BREED_SUCCESS = "ADD_BREED_SUCCESS";
export const ADD_BREED_FAILLED = "ADD_BREED_FAILLED";

export const UPDATE_BREED_REQUEST = "UPDATE_BREED_REQUEST";
export const UPDATE_BREED_SUCCESS = "UPDATE_BREED_SUCCESS";
export const UPDATE_BREED_FAILLED = "UPDATE_BREED_FAILLED";

export const DELETE_BREED_REQUEST = "DELETE_BREED_REQUEST";
export const DELETE_BREED_SUCCESS = "DELETE_BREED_SUCCESS";
export const DELETE_BREED_FAILLED = "DELETE_BREED_FAILLED";

export const GET_VACCINES_REQUEST = "GET_VACCINES_REQUEST";
export const GET_VACCINES_SUCCESS = "GET_VACCINES_SUCCESS";
export const GET_VACCINES_FAILLED = "GET_VACCINES_FAILLED";

export const ADD_VACCINE_REQUEST = "ADD_VACCINE_REQUEST";
export const ADD_VACCINE_SUCCESS = "ADD_VACCINE_SUCCESS";
export const ADD_VACCINE_FAILLED = "ADD_VACCINE_FAILLED";

export const UPDATE_VACCINE_REQUEST = "UPDATE_VACCINE_REQUEST";
export const UPDATE_VACCINE_SUCCESS = "UPDATE_VACCINE_SUCCESS";
export const UPDATE_VACCINE_FAILLED = "UPDATE_VACCINE_FAILLED";

export const DELETE_VACCINE_REQUEST = "DELETE_VACCINE_REQUEST";
export const DELETE_VACCINE_SUCCESS = "DELETE_VACCINE_SUCCESS";
export const DELETE_VACCINE_FAILLED = "DELETE_VACCINE_FAILLED";

export const GET_DIETS_REQUEST = "GET_DIETS_REQUEST";
export const GET_DIETS_SUCCESS = "GET_DIETS_SUCCESS";
export const GET_DIETS_FAILLED = "GET_DIETS_FAILLED";

export const ADD_DIET_REQUEST = "ADD_DIET_REQUEST";
export const ADD_DIET_SUCCESS = "ADD_DIET_SUCCESS";
export const ADD_DIET_FAILLED = "ADD_DIET_FAILLED";

export const UPDATE_DIET_REQUEST = "UPDATE_DIET_REQUEST";
export const UPDATE_DIET_SUCCESS = "UPDATE_DIET_SUCCESS";
export const UPDATE_DIET_FAILLED = "UPDATE_DIET_FAILLED";

export const DELETE_DIET_REQUEST = "DELETE_DIET_REQUEST";
export const DELETE_DIET_SUCCESS = "DELETE_DIET_SUCCESS";
export const DELETE_DIET_FAILLED = "DELETE_DIET_FAILLED";

export const GET_BRANDS_REQUEST = "GET_BRANDS_REQUEST";
export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS";
export const GET_BRANDS_FAILLED = "GET_BRANDS_FAILLED";

export const ADD_BRAND_REQUEST = "ADD_BRAND_REQUEST";
export const ADD_BRAND_SUCCESS = "ADD_BRAND_SUCCESS";
export const ADD_BRAND_FAILLED = "ADD_BRAND_FAILLED";

export const UPDATE_BRAND_REQUEST = "UPDATE_BRAND_REQUEST";
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS";
export const UPDATE_BRAND_FAILLED = "UPDATE_BRAND_FAILLED";

export const DELETE_BRAND_REQUEST = "DELETE_BRAND_REQUEST";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_FAILLED = "DELETE_BRAND_FAILLED";

export const GET_TOXIC_CATEGORIES_REQUEST = "GET_TOXIC_CATEGORIES_REQUEST";
export const GET_TOXIC_CATEGORIES_SUCCESS = "GET_TOXIC_CATEGORIES_SUCCESS";
export const GET_TOXIC_CATEGORIES_FAILLED = "GET_TOXIC_CATEGORIES_FAILLED";

export const ADD_TOXIC_CATEGORY_REQUEST = "ADD_TOXIC_CATEGORY_REQUEST";
export const ADD_TOXIC_CATEGORY_SUCCESS = "ADD_TOXIC_CATEGORY_SUCCESS";
export const ADD_TOXIC_CATEGORY_FAILLED = "ADD_TOXIC_CATEGORY_FAILLED";

export const UPDATE_TOXIC_CATEGORY_REQUEST = "UPDATE_TOXIC_CATEGORY_REQUEST";
export const UPDATE_TOXIC_CATEGORY_SUCCESS = "UPDATE_TOXIC_CATEGORY_SUCCESS";
export const UPDATE_TOXIC_CATEGORY_FAILLED = "UPDATE_TOXIC_CATEGORY_FAILLED";

export const DELETE_TOXIC_CATEGORY_REQUEST = "DELETE_TOXIC_CATEGORY_REQUEST";
export const DELETE_TOXIC_CATEGORY_SUCCESS = "DELETE_TOXIC_CATEGORY_SUCCESS";
export const DELETE_TOXIC_CATEGORY_FAILLED = "DELETE_TOXIC_CATEGORY_FAILLED";

export const GET_TOXICS_REQUEST = "GET_TOXICS_REQUEST";
export const GET_TOXICS_SUCCESS = "GET_TOXICS_SUCCESS";
export const GET_TOXICS_FAILLED = "GET_TOXICS_FAILLED";

export const ADD_TOXIC_IMAGE_REQUEST = "ADD_TOXIC_IMAGE_REQUEST";
export const ADD_TOXIC_IMAGE_SUCCESS = "ADD_TOXIC_IMAGE_SUCCESS";
export const ADD_TOXIC_IMAGE_FAILLED = "ADD_TOXIC_IMAGE_FAILLED";

export const ADD_TOXIC_REQUEST = "ADD_TOXIC_REQUEST";
export const ADD_TOXIC_SUCCESS = "ADD_TOXIC_SUCCESS";
export const ADD_TOXIC_FAILLED = "ADD_TOXIC_FAILLED";

export const UPDATE_TOXIC_REQUEST = "UPDATE_TOXIC_REQUEST";
export const UPDATE_TOXIC_SUCCESS = "UPDATE_TOXIC_SUCCESS";
export const UPDATE_TOXIC_FAILLED = "UPDATE_TOXIC_FAILLED";

export const DELETE_TOXIC_REQUEST = "DELETE_TOXIC_REQUEST";
export const DELETE_TOXIC_SUCCESS = "DELETE_TOXIC_SUCCESS";
export const DELETE_TOXIC_FAILLED = "DELETE_TOXIC_FAILLED";

export const GET_USERS_REQUEST = "GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILLED = "GET_USERS_FAILLED";

export const ADD_USER_IMAGE_REQUEST = "ADD_USER_IMAGE_REQUEST";
export const ADD_USER_IMAGE_SUCCESS = "ADD_USER_IMAGE_SUCCESS";
export const ADD_USER_IMAGE_FAILLED = "ADD_USER_IMAGE_FAILLED";

export const ADD_USER_REQUEST = "ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAILLED = "ADD_USER_FAILLED";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILLED = "UPDATE_USER_FAILLED";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILLED = "DELETE_USER_FAILLED";

export const GET_PETS_REQUEST = "GET_PETS_REQUEST";
export const GET_PETS_SUCCESS = "GET_PETS_SUCCESS";
export const GET_PETS_FAILLED = "GET_PETS_FAILLED";

export const ADD_PET_IMAGE_REQUEST = "ADD_PET_IMAGE_REQUEST";
export const ADD_PET_IMAGE_SUCCESS = "ADD_PET_IMAGE_SUCCESS";
export const ADD_PET_IMAGE_FAILLED = "ADD_PET_IMAGE_FAILLED";

export const ADD_PET_REQUEST = "ADD_PET_REQUEST";
export const ADD_PET_SUCCESS = "ADD_PET_SUCCESS";
export const ADD_PET_FAILLED = "ADD_PET_FAILLED";

export const UPDATE_PET_REQUEST = "UPDATE_PET_REQUEST";
export const UPDATE_PET_SUCCESS = "UPDATE_PET_SUCCESS";
export const UPDATE_PET_FAILLED = "UPDATE_PET_FAILLED";

export const DELETE_PET_REQUEST = "DELETE_PET_REQUEST";
export const DELETE_PET_SUCCESS = "DELETE_PET_SUCCESS";
export const DELETE_PET_FAILLED = "DELETE_PET_FAILLED";

export const GET_HEALTH_BOOKS_REQUEST = "GET_HEALTH_BOOKS_REQUEST";
export const GET_HEALTH_BOOKS_SUCCESS = "GET_HEALTH_BOOKS_SUCCESS";
export const GET_HEALTH_BOOKS_FAILLED = "GET_HEALTH_BOOKS_FAILLED";

export const ADD_HEALTH_BOOK_IMAGE_REQUEST = "ADD_HEALTH_BOOK_IMAGE_REQUEST";
export const ADD_HEALTH_BOOK_IMAGE_SUCCESS = "ADD_HEALTH_BOOK_IMAGE_SUCCESS";
export const ADD_HEALTH_BOOK_IMAGE_FAILLED = "ADD_HEALTH_BOOK_IMAGE_FAILLED";

export const ADD_HEALTH_BOOK_REQUEST = "ADD_HEALTH_BOOK_REQUEST";
export const ADD_HEALTH_BOOK_SUCCESS = "ADD_HEALTH_BOOK_SUCCESS";
export const ADD_HEALTH_BOOK_FAILLED = "ADD_HEALTH_BOOK_FAILLED";

export const UPDATE_HEALTH_BOOK_REQUEST = "UPDATE_HEALTH_BOOK_REQUEST";
export const UPDATE_HEALTH_BOOK_SUCCESS = "UPDATE_HEALTH_BOOK_SUCCESS";
export const UPDATE_HEALTH_BOOK_FAILLED = "UPDATE_HEALTH_BOOK_FAILLED";

export const DELETE_HEALTH_BOOK_REQUEST = "DELETE_HEALTH_BOOK_REQUEST";
export const DELETE_HEALTH_BOOK_SUCCESS = "DELETE_HEALTH_BOOK_SUCCESS";
export const DELETE_HEALTH_BOOK_FAILLED = "DELETE_HEALTH_BOOK_FAILLED";
