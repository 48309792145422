import axios from "axios";
import { toast } from "react-toastify";
import {
  ADD_USER_FAILLED,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  DELETE_USER_FAILLED,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  GET_USERS_FAILLED,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  UPDATE_USER_FAILLED,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from "../types/types";
import { USERS_URL, USER_IMAGE_URL } from "../url/url";

export const getUsers = (token, dispatch) => {
  dispatch({
    type: GET_USERS_REQUEST,
  });
  axios
    .get(USERS_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_USERS_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_USERS_FAILLED,
      });
    });
};

export const getUser = (token, id) => {
  return axios.get(`${USERS_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addUserImage = (token, body) => {
  return axios.post(USER_IMAGE_URL, body, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addUser = (token, body, closeModal, dispatch) => {
  dispatch({
    type: ADD_USER_REQUEST,
  });
  axios
    .post(USERS_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_USER_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getUsers(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: ADD_USER_FAILLED,
      });
    });
};

export const updateUser = (token, id, body, dispatch) => {
  dispatch({
    type: UPDATE_USER_REQUEST,
  });
  axios
    .post(`${USERS_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: UPDATE_USER_SUCCESS,
      });
      toast(res.data.message, { type: "success" });
      getUsers(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: UPDATE_USER_FAILLED,
      });
    });
};

export const deleteUser = (token, id, closeModal, dispatch) => {
  dispatch({
    type: DELETE_USER_REQUEST,
  });
  axios
    .delete(`${USERS_URL}/${id}`, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: DELETE_USER_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getUsers(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: DELETE_USER_FAILLED,
      });
    });
};
