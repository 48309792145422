import { Button, Col, Image, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  HealthBookModal,
  PetModal,
  UserDeleteModal,
  UserModal,
} from "../../components";
import "./styles.css";

const User = () => {
  const { users } = useSelector((state) => state.userReducer);
  const [toggleUserModal, setToggleUserModal] = useState({
    active: false,
    user: null,
  });
  const [toggleUserDeleteModal, setToggleUserDeleteModal] = useState({
    active: false,
    user: null,
  });
  const [togglePetModal, setTogglePetModal] = useState({
    active: false,
    pet: null,
  });
  const [toggleHealthBookModal, setToggleHealthBookModal] = useState({
    active: false,
    healthBook: null,
  });
  const onShowUserModal = () => {
    setToggleUserModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const onHideUserModal = () => {
    setToggleUserModal((prevState) => ({
      ...prevState,
      user: null,
      active: false,
    }));
  };
  const onSelectUser = (value) => {
    setToggleUserModal((prevState) => ({
      ...prevState,
      user: value,
      active: true,
    }));
  };

  const onHidePetModal = () => {
    setTogglePetModal((prevState) => ({
      ...prevState,
      pet: null,
      active: false,
    }));
  };
  const onSelectPet = (value) => {
    setTogglePetModal((prevState) => ({
      ...prevState,
      pet: value,
      active: true,
    }));
  };

  const onHideHealthBookModal = () => {
    setToggleHealthBookModal((prevState) => ({
      ...prevState,
      healthBook: null,
      active: false,
    }));
  };
  const onSelectHealthBook = (value) => {
    setToggleHealthBookModal((prevState) => ({
      ...prevState,
      healthBook: value,
      active: true,
    }));
  };
  const onShowUserDeleteModal = (id) => {
    setToggleUserDeleteModal((prevState) => ({
      ...prevState,
      active: true,
      user: id,
    }));
  };
  const onHideUserDeleteModal = () => {
    setToggleUserDeleteModal((prevState) => ({
      ...prevState,
      user: null,
      active: false,
    }));
  };
  return (
    <div className="userContainer">
      {toggleHealthBookModal.active && (
        <HealthBookModal
          toggleModal={toggleHealthBookModal}
          closeModal={onHideHealthBookModal}
        />
      )}
      {togglePetModal.active && (
        <PetModal toggleModal={togglePetModal} closeModal={onHidePetModal} />
      )}
      {toggleUserModal.active && (
        <UserModal toggleModal={toggleUserModal} closeModal={onHideUserModal} />
      )}
      {toggleUserDeleteModal.active && (
        <UserDeleteModal
          toggleModal={toggleUserDeleteModal}
          closeModal={onHideUserDeleteModal}
        />
      )}
      <div className="my20">
        <Button onClick={onShowUserModal}>Ajouter un user</Button>
      </div>
      <div className="usersList">
        <div className="usersListHeader">
          <Row>
            <Col className="h100" span={4}>
              <div className="usersListHeaderBorderRight">Email</div>
            </Col>
            <Col className="h100" span={5}>
              <div className="usersListHeaderBorderRight">Identifiant</div>
            </Col>
            <Col className="h100" span={10}>
              <div className="usersListHeaderBorderRight">Famille</div>
            </Col>
            <Col className="h100" span={5}>
              <div className="p10">Action</div>
            </Col>
          </Row>
        </div>
        {users.map((user) => (
          <div className="usersListHeaderBorderBottom" key={user._id}>
            <Row>
              <Col className="h100" span={4}>
                <div className="usersListHeaderBorderRight">{user.email}</div>
              </Col>
              <Col className="h100" span={5}>
                <div className="usersListHeaderBorderRight">
                  {user.username}
                </div>
              </Col>
              <Col className="h100" span={10}>
                <div className="usersListHeaderBorderRight">
                  {user.pets.map((pet) => (
                    <div key={pet._id} className="usersListFamily">
                      <div className="userFamily">
                        <div className="mr10">
                          <Image height={30} width={30} src={pet.image} />
                        </div>
                        <div className="mr10">
                          {pet.firstname} {pet.lastname}
                        </div>
                      </div>
                      <div className="userFamily">
                        <div className="mr10">
                          <button onClick={() => onSelectPet(pet._id)}>
                            Informations
                          </button>
                        </div>
                        <div className="mr10">
                          <button onClick={() => onSelectHealthBook(pet._id)}>
                            Carnet de santé
                          </button>
                        </div>
                        <div>
                          <button>Supprimer</button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
              <Col className="h100" span={4}>
                <div className="vaccinesListHeaderBorder">
                  <button onClick={() => onSelectUser(user._id)}>
                    Modifier
                  </button>
                  <button onClick={() => onShowUserDeleteModal(user)}>
                    Supprimer
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </div>
  );
};

export default User;
