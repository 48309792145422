import axios from "axios";
import { toast } from "react-toastify";
import {
  ADD_TOXIC_CATEGORY_FAILLED,
  ADD_TOXIC_CATEGORY_REQUEST,
  ADD_TOXIC_CATEGORY_SUCCESS,
  DELETE_TOXIC_CATEGORY_FAILLED,
  DELETE_TOXIC_CATEGORY_REQUEST,
  DELETE_TOXIC_CATEGORY_SUCCESS,
  GET_TOXIC_CATEGORIES_FAILLED,
  GET_TOXIC_CATEGORIES_REQUEST,
  GET_TOXIC_CATEGORIES_SUCCESS,
  UPDATE_TOXIC_CATEGORY_FAILLED,
  UPDATE_TOXIC_CATEGORY_REQUEST,
  UPDATE_TOXIC_CATEGORY_SUCCESS,
} from "../types/types";
import { TOXIC_CATEGORIES_URL } from "../url/url";

export const getToxicCategories = (token, dispatch) => {
  dispatch({
    type: GET_TOXIC_CATEGORIES_REQUEST,
  });
  axios
    .get(TOXIC_CATEGORIES_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_TOXIC_CATEGORIES_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_TOXIC_CATEGORIES_FAILLED,
      });
    });
};

export const getToxicCategory = (token, id) => {
  return axios.get(`${TOXIC_CATEGORIES_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addToxicCategory = (token, body, closeModal, dispatch) => {
  dispatch({
    type: ADD_TOXIC_CATEGORY_REQUEST,
  });
  axios
    .post(TOXIC_CATEGORIES_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_TOXIC_CATEGORY_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getToxicCategories(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: ADD_TOXIC_CATEGORY_FAILLED,
      });
    });
};

export const updateToxicCategory = (token, id, body, closeModal, dispatch) => {
  dispatch({
    type: UPDATE_TOXIC_CATEGORY_REQUEST,
  });
  axios
    .put(`${TOXIC_CATEGORIES_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: UPDATE_TOXIC_CATEGORY_SUCCESS,
      });
      toast(res.data.message, { type: "success" });
      closeModal();
      getToxicCategories(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: UPDATE_TOXIC_CATEGORY_FAILLED,
      });
    });
};

export const deleteToxicCategory = (token, id, closeModal, dispatch) => {
  dispatch({
    type: DELETE_TOXIC_CATEGORY_REQUEST,
  });
  axios
    .delete(`${TOXIC_CATEGORIES_URL}/${id}`, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: DELETE_TOXIC_CATEGORY_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getToxicCategories(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: DELETE_TOXIC_CATEGORY_FAILLED,
      });
    });
};
