import React from "react";
import { Link } from "react-router-dom";
import "./styles.css";

const Header = () => {
  return (
    <header>
      <div className="headerContainer">
        <div>
          <img src={require("../../images/logos/logo.webp")} alt="Agibulle" />
        </div>
        <div>
          <nav className="headerNav">
            <Link to="/">Accueil</Link>
            <Link to="/users">Utilisateurs</Link>
            <Link to="/animals">Animaux</Link>
            <Link to="/breeds">Races</Link>
            <Link to="/vaccines">Vaccins</Link>
            <Link to="/diets">Régimes alimentaire</Link>
            <Link to="/toxics-food">Aliments toxiques</Link>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
