import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToxic,
  addToxicImage,
  getToxic,
  updateToxic,
} from "../../redux/actions/toxicActions";
import "./styles.css";
const { Option } = Select;
const { TextArea } = Input;

function ToxicModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { toxicCategories } = useSelector(
    (state) => state.toxicCategoryReducer
  );
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (toggleModal.toxic) {
      getToxic(token, toggleModal.toxic)
        .then((res) => {
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            categoryId: res.data.categoryId,
            image: res.data.image,
            name: res.data.name,
            order: res.data.order,
            risk: res.data.risk,
            description: res.data.description,
            symptom: res.data.symptom,
            advice: res.data.advice,
            active: res.data.active,
          };
          setImage(res.data.image);
          form.setFieldsValue(fields);
        })
        .catch((err) => console.log(err));
    }
  }, [toggleModal, form, token]);

  const onChangeImage = (data) => {
    const image = data.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    addToxicImage(token, formData).then((res) => {
      console.log(res.data);
      let fields = form.getFieldsValue();
      fields = {
        ...fields,
        image: res.data,
      };
      form.setFieldsValue(fields);
      setImage(res.data);
    });
  };

  const onAddCategory = (body) => {
    if (toggleModal.toxic) {
      updateToxic(token, toggleModal.toxic, body, closeModal, dispatch);
    } else {
      addToxic(token, body, closeModal, dispatch);
    }
  };

  return (
    <Modal
      title={
        toggleModal.toxic
          ? "Modifier l'aliment toxique"
          : "Ajouter un aliment toxique"
      }
      width={1200}
      open={true}
      onCancel={closeModal}
      footer={() => null}
      className="category_modal_container"
    >
      <Form
        layout="vertical"
        onFinish={onAddCategory}
        form={form}
        initialValues={{
          image: "",
          name: "",
          order: "",
          active: true,
        }}
      >
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Photo"
              name="image"
              rules={[
                {
                  required: true,
                  message:
                    "Veuillez ajouter l'image principale de la catégorie!",
                },
              ]}
            >
              <div>
                <input
                  className="product_form_input_none"
                  onChange={(e) => onChangeImage(e)}
                  type="file"
                  id="new_category_image"
                  name="new_category_image"
                />
                <label htmlFor="new_category_image">
                  {image ? (
                    <img
                      src={image}
                      alt="preview logo"
                      className="product_form_image"
                    />
                  ) : (
                    <div className="product_form_image">+</div>
                  )}
                </label>
              </div>
            </Form.Item>
            <Form.Item
              label="Catégorie parent"
              name="categoryId"
              rules={[
                {
                  required: true,
                  message:
                    "Veuillez renseigner le nom de la catégorie de l'toxic!",
                },
              ]}
            >
              <Select>
                {toxicCategories.map((category) => (
                  <Option value={category._id} key={category._id}>
                    {category.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Nom de l'aliment'"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Veuillez ajouter le nom de l'aliment'!",
                },
              ]}
            >
              <Input placeholder="Abricot" />
            </Form.Item>
            <Form.Item label="Ordre d'affichage" name="order">
              <InputNumber placeholder="1" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item label="Active" name="active">
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Le niveau de risque de l'aliment"
              name="risk"
              rules={[
                {
                  required: true,
                  message:
                    "Veuillez renseigner le niveau de rique de l'aliment!",
                },
              ]}
            >
              <Input placeholder="Modéré à grave" />
            </Form.Item>
            <Form.Item
              label="Description de l'aliment"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner la description de l'aliment!",
                },
              ]}
            >
              <TextArea
                rows={4}
                placeholder="Le fruit en lui même n'est pas réellement toxique. Par contre la plante, les tiges, les feuilles et le noyau contiennent du cyanure pouvant être mortel..."
              />
            </Form.Item>
            <Form.Item
              label="Les symptômes"
              name="symptom"
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner les symptômes de l'aliment!",
                },
              ]}
            >
              <TextArea
                rows={4}
                placeholder="En cas d'ingestion de grosses quantités de fruit : diarrhée, vomissement principalement..."
              />
            </Form.Item>
            <Form.Item
              label="Les conseils"
              name="advice"
              rules={[
                {
                  required: true,
                  message: "Veuillez renseigner les conseils!",
                },
              ]}
            >
              <TextArea
                rows={4}
                placeholder="Empêcher les animaux de manger les fruits tombés par terre ou de grignoter les feuilles ou les branches d'arbres fruitiers..."
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ToxicModal;
