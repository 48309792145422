import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { DietDeleteModal, DietModal } from "../../components";
import "./styles.css";

const DietTemplate = () => {
  const { diets } = useSelector((state) => state.dietReducer);
  const { animals } = useSelector((state) => state.animalReducer);
  const [toggleDietModal, setToggleDietModal] = useState({
    active: false,
    diet: null,
  });
  const [toggleDietDeleteModal, setToggleDietDeleteModal] = useState({
    active: false,
    diet: null,
  });
  const onShowDietModal = () => {
    setToggleDietModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const onHideDietModal = () => {
    setToggleDietModal((prevState) => ({
      ...prevState,
      diet: null,
      active: false,
    }));
  };
  const onSelectDiet = (diet) => {
    console.log(diet);
    setToggleDietModal((prevState) => ({
      ...prevState,
      diet: diet,
      active: true,
    }));
  };
  const onShowDietDeleteModal = (diet) => {
    setToggleDietDeleteModal((prevState) => ({
      ...prevState,
      active: true,
      diet: diet,
    }));
  };
  const onHideDietDeleteModal = () => {
    setToggleDietDeleteModal((prevState) => ({
      ...prevState,
      diet: null,
      active: false,
    }));
  };
  return (
    <div className="dietContainer">
      {toggleDietModal.active && (
        <DietModal toggleModal={toggleDietModal} closeModal={onHideDietModal} />
      )}
      {toggleDietDeleteModal.active && (
        <DietDeleteModal
          toggleModal={toggleDietDeleteModal}
          closeModal={onHideDietDeleteModal}
        />
      )}
      <div className="my20">
        <Button onClick={onShowDietModal}>Ajouter un régime alimentaire</Button>
      </div>
      <div className="dietsList">
        <div className="dietsListHeader">
          <Row>
            <Col className="h100" span={4}>
              <div className="dietsListHeaderBorderRight">Id</div>
            </Col>
            <Col className="h100" span={5}>
              <div className="dietsListHeaderBorderRight">Nom</div>
            </Col>
            <Col className="h100" span={5}>
              <div className="dietsListHeaderBorderRight">Animal</div>
            </Col>
            <Col className="h100" span={5}>
              <div className="dietsListHeaderBorderRight">Ordre</div>
            </Col>
            <Col className="h100" span={5}>
              <div className="p10">Action</div>
            </Col>
          </Row>
        </div>
        {diets.map((diet) => {
          let filteredAnimalIds = [];
          for (let j = 0; j < diet.animalIds.length; j++) {
            const isExist = animals.find(
              (animal) => animal._id === diet.animalIds[j]
            );
            if (isExist) {
              filteredAnimalIds.push(diet.animalIds[j]);
            }
          }
          return (
            <div className="dietsListHeaderBorderBottom" key={diet._id}>
              <Row>
                <Col className="h100" span={4}>
                  <div className="dietsListHeaderBorderRight">{diet._id}</div>
                </Col>
                <Col className="h100" span={5}>
                  <div className="dietsListHeaderBorderRight">{diet.name}</div>
                </Col>
                <Col className="h100" span={5}>
                  <div className="dietsListHeaderBorderRight">
                    {filteredAnimalIds.map((animal, i) =>
                      i === 0
                        ? animals[
                            animals.findIndex((item) => item._id === animal)
                          ].name
                        : `, ${
                            animals[
                              animals.findIndex((item) => item._id === animal)
                            ].name
                          }`
                    )}
                  </div>
                </Col>
                <Col className="h100" span={5}>
                  <div className="dietsListHeaderBorderRight">{diet.order}</div>
                </Col>
                <Col className="h100" span={5}>
                  <div className="dietsListHeaderBorder">
                    <button onClick={() => onSelectDiet(diet._id)}>
                      Modifier
                    </button>
                    <button onClick={() => onShowDietDeleteModal(diet)}>
                      Supprimer
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DietTemplate;
