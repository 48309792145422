import { Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteToxicCategory } from "../../redux/actions/toxicCategoryActions";
import "./styles.css";

function ToxicCategoryDeleteModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();

  const onDeleteToxicCategory = () => {
    deleteToxicCategory(
      token,
      toggleModal.toxicCategory._id,
      closeModal,
      dispatch
    );
  };

  return (
    <Modal
      title={`Supprimer la catégorie ${toggleModal.toxicCategory.name} de l'aliment toxique`}
      open={true}
      onCancel={closeModal}
      footer={() => null}
      className="category_modal_container"
    >
      <div>
        <button onClick={onDeleteToxicCategory}>Oui</button>
        <button onClick={closeModal}>Non</button>
      </div>
    </Modal>
  );
}

export default ToxicCategoryDeleteModal;
