import axios from "axios";
import { toast } from "react-toastify";
import {
  ADD_PET_FAILLED,
  ADD_PET_REQUEST,
  ADD_PET_SUCCESS,
  DELETE_PET_FAILLED,
  DELETE_PET_REQUEST,
  DELETE_PET_SUCCESS,
  GET_PETS_FAILLED,
  GET_PETS_REQUEST,
  GET_PETS_SUCCESS,
  UPDATE_PET_FAILLED,
  UPDATE_PET_REQUEST,
  UPDATE_PET_SUCCESS,
} from "../types/types";
import { PETS_URL, PET_IMAGE_URL } from "../url/url";
import { getUsers } from "./userActions";

export const getPets = (token, dispatch) => {
  dispatch({
    type: GET_PETS_REQUEST,
  });
  axios
    .get(PETS_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_PETS_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_PETS_FAILLED,
      });
    });
};

export const getPet = (token, id) => {
  return axios.get(`${PETS_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addPetImage = (token, body) => {
  return axios.post(PET_IMAGE_URL, body, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addPet = (token, body, closeModal, dispatch) => {
  dispatch({
    type: ADD_PET_REQUEST,
  });
  axios
    .post(PETS_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_PET_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getPets(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: ADD_PET_FAILLED,
      });
    });
};

export const updatePet = (token, id, body, closeModal, dispatch) => {
  dispatch({
    type: UPDATE_PET_REQUEST,
  });
  axios
    .put(`${PETS_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: UPDATE_PET_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getUsers(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: UPDATE_PET_FAILLED,
      });
    });
};

export const deletePet = (token, id, closeModal, dispatch) => {
  dispatch({
    type: DELETE_PET_REQUEST,
  });
  axios
    .delete(`${PETS_URL}/${id}`, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: DELETE_PET_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getPets(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: DELETE_PET_FAILLED,
      });
    });
};
