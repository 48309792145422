import axios from "axios";
import { toast } from "react-toastify";
import {
  ADD_HEALTH_BOOK_FAILLED,
  ADD_HEALTH_BOOK_REQUEST,
  ADD_HEALTH_BOOK_SUCCESS,
  DELETE_HEALTH_BOOK_FAILLED,
  DELETE_HEALTH_BOOK_REQUEST,
  DELETE_HEALTH_BOOK_SUCCESS,
  GET_HEALTH_BOOKS_FAILLED,
  GET_HEALTH_BOOKS_REQUEST,
  GET_HEALTH_BOOKS_SUCCESS,
  UPDATE_HEALTH_BOOK_FAILLED,
  UPDATE_HEALTH_BOOK_REQUEST,
  UPDATE_HEALTH_BOOK_SUCCESS,
} from "../types/types";
import { HEALTH_BOOKS_URL } from "../url/url";
import { getUsers } from "./userActions";

export const getHealthBooks = (token, dispatch) => {
  dispatch({
    type: GET_HEALTH_BOOKS_REQUEST,
  });
  axios
    .get(HEALTH_BOOKS_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_HEALTH_BOOKS_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_HEALTH_BOOKS_FAILLED,
      });
    });
};

export const getHealthBook = (token, id) => {
  return axios.get(`${HEALTH_BOOKS_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addHealthBook = (token, body, closeModal, dispatch) => {
  dispatch({
    type: ADD_HEALTH_BOOK_REQUEST,
  });
  axios
    .post(HEALTH_BOOKS_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_HEALTH_BOOK_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getHealthBooks(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: ADD_HEALTH_BOOK_FAILLED,
      });
    });
};

export const updateHealthBook = (token, id, body, closeModal, dispatch) => {
  dispatch({
    type: UPDATE_HEALTH_BOOK_REQUEST,
  });
  axios
    .put(`${HEALTH_BOOKS_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: UPDATE_HEALTH_BOOK_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getUsers(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: UPDATE_HEALTH_BOOK_FAILLED,
      });
    });
};

export const deleteHealthBook = (token, id, closeModal, dispatch) => {
  dispatch({
    type: DELETE_HEALTH_BOOK_REQUEST,
  });
  axios
    .delete(`${HEALTH_BOOKS_URL}/${id}`, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: DELETE_HEALTH_BOOK_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getHealthBooks(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: DELETE_HEALTH_BOOK_FAILLED,
      });
    });
};
