import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ToxicCategoryDeleteModal from "../toxic-category-delete-modal/ToxicCategoryDeleteModal";
import ToxicCategoryModal from "../toxic-category-modal/ToxicCategoryModal";
import "./styles.css";

const ToxicCategory = () => {
  const { toxicCategories } = useSelector(
    (state) => state.toxicCategoryReducer
  );
  const [toggleToxicCategoryModal, setToggleToxicCategoryModal] = useState({
    active: false,
    toxicCategory: null,
  });
  const [toggleToxicCategoryDeleteModal, setToggleToxicCategoryDeleteModal] =
    useState({
      active: false,
      toxicCategory: null,
    });
  const onShowToxicCategoryModal = () => {
    setToggleToxicCategoryModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const onHideToxicCategoryModal = () => {
    setToggleToxicCategoryModal((prevState) => ({
      ...prevState,
      toxicCategory: null,
      active: false,
    }));
  };
  const onSelectToxicCategory = (value) => {
    setToggleToxicCategoryModal((prevState) => ({
      ...prevState,
      toxicCategory: value,
      active: true,
    }));
  };
  const onShowToxicCategoryDeleteModal = (id) => {
    setToggleToxicCategoryDeleteModal((prevState) => ({
      ...prevState,
      active: true,
      toxicCategory: id,
    }));
  };
  const onHideToxicCategoryDeleteModal = () => {
    setToggleToxicCategoryDeleteModal((prevState) => ({
      ...prevState,
      toxicCategory: null,
      active: false,
    }));
  };
  return (
    <div className="toxicCategoryContainer">
      {toggleToxicCategoryModal.active && (
        <ToxicCategoryModal
          toggleModal={toggleToxicCategoryModal}
          closeModal={onHideToxicCategoryModal}
        />
      )}
      {toggleToxicCategoryDeleteModal.active && (
        <ToxicCategoryDeleteModal
          toggleModal={toggleToxicCategoryDeleteModal}
          closeModal={onHideToxicCategoryDeleteModal}
        />
      )}
      <div className="my20">
        <Button onClick={onShowToxicCategoryModal}>
          Ajouter une catégorie d'aliment toxique
        </Button>
      </div>
      <div className="toxicCategoriesList">
        <div className="toxicCategoriesListHeader">
          <Row>
            <Col className="h100" span={2}>
              <div className="toxicsListHeaderBorderRight">Image</div>
            </Col>
            <Col className="h100" span={4}>
              <div className="toxicCategoriesListHeaderBorderRight">Id</div>
            </Col>
            <Col className="h100" span={6}>
              <div className="toxicCategoriesListHeaderBorderRight">Nom</div>
            </Col>
            <Col className="h100" span={6}>
              <div className="toxicCategoriesListHeaderBorderRight">Ordre</div>
            </Col>
            <Col className="h100" span={6}>
              <div className="p10">Action</div>
            </Col>
          </Row>
        </div>
        {toxicCategories.map((toxicCategory) => (
          <div
            className="toxicCategoriesListHeaderBorderBottom"
            key={toxicCategory._id}
          >
            <Row>
              <Col className="h100" span={2}>
                <div className="toxicsListHeaderBorderRight">
                  <img src={toxicCategory.image} alt={toxicCategory.name} />
                </div>
              </Col>
              <Col className="h100" span={4}>
                <div className="toxicCategoriesListHeaderBorderRight">
                  {toxicCategory._id}
                </div>
              </Col>
              <Col className="h100" span={6}>
                <div className="toxicCategoriesListHeaderBorderRight">
                  {toxicCategory.name}
                </div>
              </Col>
              <Col className="h100" span={6}>
                <div className="toxicCategoriesListHeaderBorderRight">
                  {toxicCategory.order}
                </div>
              </Col>
              <Col className="h100" span={6}>
                <div className="vaccinesListHeaderBorder">
                  <button
                    onClick={() => onSelectToxicCategory(toxicCategory._id)}
                  >
                    Modifier
                  </button>
                  <button
                    onClick={() =>
                      onShowToxicCategoryDeleteModal(toxicCategory)
                    }
                  >
                    Supprimer
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ToxicCategory;
