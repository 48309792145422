import axios from "axios";
import { toast } from "react-toastify";
import {
  ADD_DIET_FAILLED,
  ADD_DIET_REQUEST,
  ADD_DIET_SUCCESS,
  DELETE_DIET_FAILLED,
  DELETE_DIET_REQUEST,
  DELETE_DIET_SUCCESS,
  GET_DIETS_FAILLED,
  GET_DIETS_REQUEST,
  GET_DIETS_SUCCESS,
  UPDATE_DIET_FAILLED,
  UPDATE_DIET_REQUEST,
  UPDATE_DIET_SUCCESS,
} from "../types/types";
import { DIETS_URL } from "../url/url";

export const getDiets = (token, dispatch) => {
  dispatch({
    type: GET_DIETS_REQUEST,
  });
  axios
    .get(DIETS_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: GET_DIETS_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_DIETS_FAILLED,
      });
    });
};

export const getDiet = (token, id) => {
  return axios.get(`${DIETS_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addDiet = (token, body, closeModal, dispatch) => {
  dispatch({
    type: ADD_DIET_REQUEST,
  });
  axios
    .post(DIETS_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_DIET_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getDiets(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: ADD_DIET_FAILLED,
      });
    });
};

export const updateDiet = (token, id, body, closeModal, dispatch) => {
  dispatch({
    type: UPDATE_DIET_REQUEST,
  });
  axios
    .put(`${DIETS_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: UPDATE_DIET_SUCCESS,
      });
      toast(res.data.message, { type: "success" });
      getDiets(token, dispatch);
      closeModal();
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: UPDATE_DIET_FAILLED,
      });
    });
};

export const deleteDiet = (token, id, closeModal, dispatch) => {
  dispatch({
    type: DELETE_DIET_REQUEST,
  });
  axios
    .delete(`${DIETS_URL}/${id}`, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: DELETE_DIET_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getDiets(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: DELETE_DIET_FAILLED,
      });
    });
};
