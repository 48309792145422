import { Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteBrand } from "../../redux/actions/brandActions";
import "./styles.css";

function BrandDeleteModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();

  const onDeleteBrand = () => {
    deleteBrand(token, toggleModal.brand._id, closeModal, dispatch);
  };

  return (
    <Modal
      title={`Supprimer le régime alimentaire ${toggleModal.brand.name}`}
      open={true}
      onCancel={closeModal}
      footer={() => null}
      className="category_modal_container"
    >
      <div>
        <button onClick={onDeleteBrand}>Oui</button>
        <button onClick={closeModal}>Non</button>
      </div>
    </Modal>
  );
}

export default BrandDeleteModal;
