import {
  GET_USERS_FAILLED,
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
} from "../types/types";

const initialeState = {
  users: [],
  usersLoading: false,
};

const userReducer = (state = initialeState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USERS_REQUEST:
      return {
        ...state,
        usersLoading: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        usersLoading: false,
        users: payload,
      };
    case GET_USERS_FAILLED:
      return {
        ...state,
        usersLoading: false,
      };
    default:
      return state;
  }
};

export default userReducer;
