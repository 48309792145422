import { Button, DatePicker, Form, Input, Modal, Select, Switch } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addPet,
  addPetImage,
  getPet,
  updatePet,
} from "../../redux/actions/petActions";
import "./styles.css";
const { Option } = Select;

function PetModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { animals } = useSelector((state) => state.animalReducer);
  const { breeds } = useSelector((state) => state.breedReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);
  const [sexe, setSexe] = useState("");
  const [birthdate, setBirthdate] = useState("");

  useEffect(() => {
    if (toggleModal.pet) {
      getPet(token, toggleModal.pet)
        .then((res) => {
          console.log(res.data);
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            image: res.data.image,
            firstname: res.data.firstname,
            lastname: res.data.lastname,
            chip: res.data.chip,
            animal: res.data.animal,
            breed: res.data.breed,
            sexe: res.data.sexe,
            birthdate: res.data.birthdate,
            active: res.data.active,
          };
          setSexe(res.data.sexe);
          setImage(res.data.image);
          setBirthdate(res.data.birthdate);
          form.setFieldsValue(fields);
        })
        .catch((err) => console.log(err));
    }
  }, [toggleModal, form, token]);

  const onChangeImage = (data) => {
    const image = data.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    addPetImage(token, formData).then((res) => {
      console.log(res.data);
      let fields = form.getFieldsValue();
      fields = {
        ...fields,
        image: res.data,
      };
      form.setFieldsValue(fields);
      setImage(res.data);
    });
  };

  const onAddCategory = (values) => {
    const body = {
      ...values,
      birthdate,
    };
    console.log(body);
    if (toggleModal.pet) {
      updatePet(token, toggleModal.pet, body, closeModal, dispatch);
    } else {
      addPet(token, body, closeModal, dispatch);
    }
  };

  const onAnimalChange = (value) => {
    form.setFieldsValue({ animal: value });
  };

  const onBreedChange = (value) => {
    form.setFieldsValue({ breed: value });
  };

  const onSexeChange = (value) => {
    form.setFieldsValue({ sexe: value });
    setSexe(value);
  };

  const onBirthdateChange = (date, dateString) => {
    form.setFieldsValue({ birthdate: dateString });
    setBirthdate(new Date(date));
  };

  return (
    <Modal
      title={
        toggleModal.pet
          ? "Modifier la catégorie de l'pet"
          : "Ajouter une catégorie d'pet"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
      className="category_modal_container"
    >
      <Form
        layout="vertical"
        onFinish={onAddCategory}
        form={form}
        initialValues={{
          image: "",
          name: "",
          order: "",
          active: true,
        }}
      >
        <Form.Item
          label="Photo"
          name="image"
          rules={[
            {
              required: true,
              message: "Veuillez ajouter l'image principale de la catégorie!",
            },
          ]}
        >
          <div>
            <input
              className="product_form_input_none"
              onChange={(e) => onChangeImage(e)}
              type="file"
              id="new_category_image"
              name="new_category_image"
            />
            <label htmlFor="new_category_image">
              {image ? (
                <img
                  src={image}
                  alt="preview logo"
                  className="product_form_image"
                />
              ) : (
                <div className="product_form_image">+</div>
              )}
            </label>
          </div>
        </Form.Item>
        <Form.Item
          label="Nom du membre"
          name="lastname"
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie de l'pet!",
            },
          ]}
        >
          <Input placeholder="Chat" />
        </Form.Item>
        <Form.Item
          label="Prénom du membre"
          name="firstname"
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie de l'pet!",
            },
          ]}
        >
          <Input placeholder="Chat" />
        </Form.Item>
        <Form.Item
          label="Catégorie du membre"
          name="animal"
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie de l'pet!",
            },
          ]}
        >
          <Select
            placeholder="Select a option and change input text above"
            onChange={onAnimalChange}
            allowClear
          >
            {animals.map((animal) => (
              <Option key={animal._id} value={animal._id}>
                {animal.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Race du membre"
          name="breed"
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie de l'pet!",
            },
          ]}
        >
          <Select
            placeholder="Select a option and change input text above"
            onChange={onBreedChange}
            allowClear
          >
            {breeds.map((breed) => (
              <Option key={breed._id} value={breed._id}>
                {breed.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Sexe du membre"
          name="sexe"
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie de l'pet!",
            },
          ]}
        >
          <div>
            <Button
              onClick={() => onSexeChange("Mâle")}
              type={sexe === "Mâle" ? "primary" : "default"}
            >
              Male
            </Button>
            <Button
              onClick={() => onSexeChange("Femelle")}
              type={sexe === "Femelle" ? "primary" : "default"}
            >
              Femelle
            </Button>
          </div>
        </Form.Item>
        {birthdate && (
          <Form.Item
            label="Date de naissance du membre"
            rules={[
              {
                required: true,
                message: "Veuillez ajouter le nom de la catégorie de l'pet!",
              },
            ]}
          >
            <DatePicker
              format="DD/MM/YYYY"
              onChange={onBirthdateChange}
              defaultValue={dayjs(birthdate)}
              allowClear={false}
            />
          </Form.Item>
        )}
        <Form.Item
          label="Numéro de puce du membre"
          name="chip"
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la catégorie de l'pet!",
            },
          ]}
        >
          <Input placeholder="Chat" />
        </Form.Item>
        <Form.Item label="Active" name="active">
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default PetModal;
