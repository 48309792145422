import axios from "axios";
import { toast } from "react-toastify";
import {
  ADD_BRAND_FAILLED,
  ADD_BRAND_REQUEST,
  ADD_BRAND_SUCCESS,
  DELETE_BRAND_FAILLED,
  DELETE_BRAND_REQUEST,
  DELETE_BRAND_SUCCESS,
  GET_BRANDS_FAILLED,
  GET_BRANDS_REQUEST,
  GET_BRANDS_SUCCESS,
  UPDATE_BRAND_FAILLED,
  UPDATE_BRAND_REQUEST,
  UPDATE_BRAND_SUCCESS,
} from "../types/types";
import { BRANDS_URL } from "../url/url";

export const getBrands = (token, dispatch) => {
  dispatch({
    type: GET_BRANDS_REQUEST,
  });
  axios
    .get(BRANDS_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: GET_BRANDS_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_BRANDS_FAILLED,
      });
    });
};

export const getBrand = (token, id) => {
  return axios.get(`${BRANDS_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addBrand = (token, body, closeModal, dispatch) => {
  dispatch({
    type: ADD_BRAND_REQUEST,
  });
  axios
    .post(BRANDS_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_BRAND_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getBrands(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: ADD_BRAND_FAILLED,
      });
    });
};

export const updateBrand = (token, id, body, closeModal, dispatch) => {
  dispatch({
    type: UPDATE_BRAND_REQUEST,
  });
  axios
    .put(`${BRANDS_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: UPDATE_BRAND_SUCCESS,
      });
      toast(res.data.message, { type: "success" });
      getBrands(token, dispatch);
      closeModal();
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: UPDATE_BRAND_FAILLED,
      });
    });
};

export const deleteBrand = (token, id, closeModal, dispatch) => {
  dispatch({
    type: DELETE_BRAND_REQUEST,
  });
  axios
    .delete(`${BRANDS_URL}/${id}`, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: DELETE_BRAND_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getBrands(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: DELETE_BRAND_FAILLED,
      });
    });
};
