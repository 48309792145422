import {
  ADD_VACCINE_FAILLED,
  ADD_VACCINE_REQUEST,
  ADD_VACCINE_SUCCESS,
  DELETE_VACCINE_FAILLED,
  DELETE_VACCINE_REQUEST,
  DELETE_VACCINE_SUCCESS,
  GET_VACCINES_FAILLED,
  GET_VACCINES_REQUEST,
  GET_VACCINES_SUCCESS,
  UPDATE_VACCINE_FAILLED,
  UPDATE_VACCINE_REQUEST,
  UPDATE_VACCINE_SUCCESS,
} from "../types/types";

const initialeState = {
  vaccines: [],
  vaccinesLoading: false,
  vaccineUpdateLoading: false,
};

const vaccineReducer = (state = initialeState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_VACCINES_REQUEST:
      return {
        ...state,
        vaccinesLoading: true,
      };
    case GET_VACCINES_SUCCESS:
      return {
        ...state,
        vaccines: payload,
        vaccinesLoading: false,
      };
    case GET_VACCINES_FAILLED:
      return {
        ...state,
        vaccinesLoading: false,
      };
    case ADD_VACCINE_REQUEST:
      return {
        ...state,
        vaccineUpdateLoading: true,
      };
    case ADD_VACCINE_SUCCESS:
      return {
        ...state,
        vaccineUpdateLoading: false,
      };
    case ADD_VACCINE_FAILLED:
      return {
        ...state,
        vaccineUpdateLoading: false,
      };
    case UPDATE_VACCINE_REQUEST:
      return {
        ...state,
        vaccineUpdateLoading: true,
      };
    case UPDATE_VACCINE_SUCCESS:
      return {
        ...state,
        vaccineUpdateLoading: false,
      };
    case UPDATE_VACCINE_FAILLED:
      return {
        ...state,
        vaccineUpdateLoading: false,
      };
    case DELETE_VACCINE_REQUEST:
      return {
        ...state,
        vaccineUpdateLoading: true,
      };
    case DELETE_VACCINE_SUCCESS:
      return {
        ...state,
        vaccineUpdateLoading: false,
      };
    case DELETE_VACCINE_FAILLED:
      return {
        ...state,
        vaccineUpdateLoading: false,
      };
    default:
      return state;
  }
};

export default vaccineReducer;
