import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { BreedDeleteModal, BreedModal } from "../../components";
import "./styles.css";

const Breed = () => {
  const { breeds } = useSelector((state) => state.breedReducer);
  const { animals } = useSelector((state) => state.animalReducer);
  const [toggleBreedModal, setToggleBreedModal] = useState({
    active: false,
    breed: null,
  });
  const [toggleBreedDeleteModal, setToggleBreedDeleteModal] = useState({
    active: false,
    breed: null,
  });
  const onShowBreedModal = () => {
    setToggleBreedModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const onHideBreedModal = () => {
    setToggleBreedModal((prevState) => ({
      ...prevState,
      breed: null,
      active: false,
    }));
  };
  const onSelectBreed = (value) => {
    setToggleBreedModal((prevState) => ({
      ...prevState,
      breed: value,
      active: true,
    }));
  };
  const onShowBreedDeleteModal = (breed) => {
    setToggleBreedDeleteModal((prevState) => ({
      ...prevState,
      active: true,
      breed: breed,
    }));
  };
  const onHideBreedDeleteModal = () => {
    setToggleBreedDeleteModal((prevState) => ({
      ...prevState,
      breed: null,
      active: false,
    }));
  };
  return (
    <div className="breedContainer">
      {toggleBreedModal.active && (
        <BreedModal
          toggleModal={toggleBreedModal}
          closeModal={onHideBreedModal}
        />
      )}
      {toggleBreedDeleteModal.active && (
        <BreedDeleteModal
          toggleModal={toggleBreedDeleteModal}
          closeModal={onHideBreedDeleteModal}
        />
      )}
      <div className="my20">
        <Button onClick={onShowBreedModal}>Ajouter une race</Button>
      </div>
      <div className="breedsList">
        <div className="breedsListHeader">
          <Row>
            <Col className="h100" span={4}>
              <div className="breedsListHeaderBorderRight">Id</div>
            </Col>
            <Col className="h100" span={5}>
              <div className="breedsListHeaderBorderRight">Nom</div>
            </Col>
            <Col className="h100" span={5}>
              <div className="breedsListHeaderBorderRight">Animal</div>
            </Col>
            <Col className="h100" span={5}>
              <div className="breedsListHeaderBorderRight">Ordre</div>
            </Col>
            <Col className="h100" span={5}>
              <div className="p10">Action</div>
            </Col>
          </Row>
        </div>
        {breeds.map((breed, i) => {
          const animal = animals.find((item) => item._id === breed.animalId);
          return animal ? (
            <div className="breedsListHeaderBorderBottom" key={breed._id}>
              <Row>
                <Col className="h100" span={4}>
                  <div className="breedsListHeaderBorderRight">{breed._id}</div>
                </Col>
                <Col className="h100" span={5}>
                  <div className="breedsListHeaderBorderRight">
                    {breed.name}
                  </div>
                </Col>
                <Col className="h100" span={5}>
                  <div className="breedsListHeaderBorderRight">
                    {animal.name}
                  </div>
                </Col>
                <Col className="h100" span={5}>
                  <div className="breedsListHeaderBorderRight">
                    {breed.order}
                  </div>
                </Col>
                <Col className="h100" span={4}>
                  <div className="vaccinesListHeaderBorder">
                    <button onClick={() => onSelectBreed(breed._id)}>
                      Modifier
                    </button>
                    <button onClick={() => onShowBreedDeleteModal(breed)}>
                      Supprimer
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          ) : (
            <div key={i} />
          );
        })}
      </div>
    </div>
  );
};

export default Breed;
