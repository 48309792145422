import { Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAnimal } from "../../redux/actions/animalActions";
import "./styles.css";

function AnimalDeleteModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();

  const onDeleteAnimal = () => {
    deleteAnimal(token, toggleModal.animal._id, closeModal, dispatch);
  };

  return (
    <Modal
      title={`Supprimer l'animal ${toggleModal.animal.name}`}
      open={true}
      onCancel={closeModal}
      footer={() => null}
      className="category_modal_container"
    >
      <div>
        <button onClick={onDeleteAnimal}>Oui</button>
        <button onClick={closeModal}>Non</button>
      </div>
    </Modal>
  );
}

export default AnimalDeleteModal;
