import React from "react";
import { ToxicCategory, ToxicTemplate } from "../../components";

const Toxic = () => {
  return (
    <div>
      <ToxicCategory />
      <ToxicTemplate />
    </div>
  );
};

export default Toxic;
