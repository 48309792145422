import axios from "axios";
import { toast } from "react-toastify";
import {
  ADD_VACCINE_FAILLED,
  ADD_VACCINE_REQUEST,
  ADD_VACCINE_SUCCESS,
  DELETE_VACCINE_FAILLED,
  DELETE_VACCINE_REQUEST,
  DELETE_VACCINE_SUCCESS,
  GET_VACCINES_FAILLED,
  GET_VACCINES_REQUEST,
  GET_VACCINES_SUCCESS,
  UPDATE_VACCINE_FAILLED,
  UPDATE_VACCINE_REQUEST,
  UPDATE_VACCINE_SUCCESS,
} from "../types/types";
import { VACCINES_URL } from "../url/url";

export const getVaccines = (token, dispatch) => {
  dispatch({
    type: GET_VACCINES_REQUEST,
  });
  axios
    .get(VACCINES_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      console.log(res.data);
      dispatch({
        type: GET_VACCINES_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_VACCINES_FAILLED,
      });
    });
};

export const getVaccine = (token, id) => {
  return axios.get(`${VACCINES_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addVaccine = (token, body, closeModal, dispatch) => {
  dispatch({
    type: ADD_VACCINE_REQUEST,
  });
  axios
    .post(VACCINES_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_VACCINE_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getVaccines(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: ADD_VACCINE_FAILLED,
      });
    });
};

export const updateVaccine = (token, id, body, closeModal, dispatch) => {
  dispatch({
    type: UPDATE_VACCINE_REQUEST,
  });
  axios
    .put(`${VACCINES_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: UPDATE_VACCINE_SUCCESS,
      });
      toast(res.data.message, { type: "success" });
      getVaccines(token, dispatch);
      closeModal();
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: UPDATE_VACCINE_FAILLED,
      });
    });
};

export const deleteVaccine = (token, id, closeModal, dispatch) => {
  dispatch({
    type: DELETE_VACCINE_REQUEST,
  });
  axios
    .delete(`${VACCINES_URL}/${id}`, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: DELETE_VACCINE_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getVaccines(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: DELETE_VACCINE_FAILLED,
      });
    });
};
