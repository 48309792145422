import { Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteDiet } from "../../redux/actions/dietActions";
import "./styles.css";

function DietDeleteModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();

  const onDeleteVaccine = () => {
    deleteDiet(token, toggleModal.diet._id, closeModal, dispatch);
  };

  return (
    <Modal
      title={`Supprimer le régime alimentaire ${toggleModal.diet.name}`}
      open={true}
      onCancel={closeModal}
      footer={() => null}
      className="category_modal_container"
    >
      <div>
        <button onClick={onDeleteVaccine}>Oui</button>
        <button onClick={closeModal}>Non</button>
      </div>
    </Modal>
  );
}

export default DietDeleteModal;
