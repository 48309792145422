import axios from "axios";
import { toast } from "react-toastify";
import {
  ADD_TOXIC_FAILLED,
  ADD_TOXIC_REQUEST,
  ADD_TOXIC_SUCCESS,
  DELETE_TOXIC_FAILLED,
  DELETE_TOXIC_REQUEST,
  DELETE_TOXIC_SUCCESS,
  GET_TOXICS_FAILLED,
  GET_TOXICS_REQUEST,
  GET_TOXICS_SUCCESS,
  UPDATE_TOXIC_FAILLED,
  UPDATE_TOXIC_REQUEST,
  UPDATE_TOXIC_SUCCESS,
} from "../types/types";
import { TOXICS_URL, TOXIC_IMAGE_URL } from "../url/url";

export const getToxics = (token, dispatch) => {
  dispatch({
    type: GET_TOXICS_REQUEST,
  });
  axios
    .get(TOXICS_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_TOXICS_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_TOXICS_FAILLED,
      });
    });
};

export const getToxic = (token, id) => {
  return axios.get(`${TOXICS_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addToxicImage = (token, body) => {
  return axios.post(TOXIC_IMAGE_URL, body, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addToxic = (token, body, closeModal, dispatch) => {
  dispatch({
    type: ADD_TOXIC_REQUEST,
  });
  axios
    .post(TOXICS_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_TOXIC_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getToxics(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: ADD_TOXIC_FAILLED,
      });
    });
};

export const updateToxic = (token, id, body, closeModal, dispatch) => {
  dispatch({
    type: UPDATE_TOXIC_REQUEST,
  });
  axios
    .put(`${TOXICS_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: UPDATE_TOXIC_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getToxics(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: UPDATE_TOXIC_FAILLED,
      });
    });
};

export const deleteToxic = (token, id, closeModal, dispatch) => {
  dispatch({
    type: DELETE_TOXIC_REQUEST,
  });
  axios
    .delete(`${TOXICS_URL}/${id}`, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: DELETE_TOXIC_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getToxics(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: DELETE_TOXIC_FAILLED,
      });
    });
};
