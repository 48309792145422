import { Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteVaccine } from "../../redux/actions/vaccineActions";
import "./styles.css";

function VaccineDeleteModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();

  const onDeleteVaccine = () => {
    deleteVaccine(token, toggleModal.vaccine._id, closeModal, dispatch);
  };

  return (
    <Modal
      title={`Supprimer le vaccin ${toggleModal.vaccine.name}`}
      open={true}
      onCancel={closeModal}
      footer={() => null}
      className="category_modal_container"
    >
      <div>
        <button onClick={onDeleteVaccine}>Oui</button>
        <button onClick={closeModal}>Non</button>
      </div>
    </Modal>
  );
}

export default VaccineDeleteModal;
