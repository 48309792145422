import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { VaccineDeleteModal, VaccineModal } from "../../components";
import "./styles.css";

const Vaccine = () => {
  const { vaccines } = useSelector((state) => state.vaccineReducer);
  const { animals } = useSelector((state) => state.animalReducer);
  const [toggleVaccineModal, setToggleVaccineModal] = useState({
    active: false,
    vaccine: null,
  });
  const [toggleVaccineDeleteModal, setToggleVaccineDeleteModal] = useState({
    active: false,
    vaccine: null,
  });
  const onShowVaccineModal = () => {
    setToggleVaccineModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const onHideVaccineModal = () => {
    setToggleVaccineModal((prevState) => ({
      ...prevState,
      vaccine: null,
      active: false,
    }));
  };
  const onSelectVaccine = (vaccine) => {
    setToggleVaccineModal((prevState) => ({
      ...prevState,
      vaccine: vaccine,
      active: true,
    }));
  };
  const onShowVaccineDeleteModal = (vaccine) => {
    setToggleVaccineDeleteModal((prevState) => ({
      ...prevState,
      active: true,
      vaccine: vaccine,
    }));
  };
  const onHideVaccineDeleteModal = () => {
    setToggleVaccineDeleteModal((prevState) => ({
      ...prevState,
      vaccine: null,
      active: false,
    }));
  };
  return (
    <div className="vaccineContainer">
      {toggleVaccineModal.active && (
        <VaccineModal
          toggleModal={toggleVaccineModal}
          closeModal={onHideVaccineModal}
        />
      )}
      {toggleVaccineDeleteModal.active && (
        <VaccineDeleteModal
          toggleModal={toggleVaccineDeleteModal}
          closeModal={onHideVaccineDeleteModal}
        />
      )}
      <div className="my20">
        <Button onClick={onShowVaccineModal}>Ajouter un vaccin</Button>
      </div>
      <div className="vaccinesList">
        <div className="vaccinesListHeader">
          <Row>
            <Col className="h100" span={4}>
              <div className="vaccinesListHeaderBorderRight">Id</div>
            </Col>
            <Col className="h100" span={4}>
              <div className="vaccinesListHeaderBorderRight">Nom</div>
            </Col>
            <Col className="h100" span={4}>
              <div className="vaccinesListHeaderBorderRight">Animal</div>
            </Col>
            <Col className="h100" span={4}>
              <div className="vaccinesListHeaderBorderRight">
                Années de validité
              </div>
            </Col>
            <Col className="h100" span={4}>
              <div className="vaccinesListHeaderBorderRight">Ordre</div>
            </Col>
            <Col className="h100" span={4}>
              <div className="p10">Action</div>
            </Col>
          </Row>
        </div>
        {vaccines.map((vaccine) => {
          let filteredAnimalIds = [];
          for (let j = 0; j < vaccine.animalIds.length; j++) {
            const isExist = animals.find(
              (animal) => animal._id === vaccine.animalIds[j]
            );
            if (isExist) {
              filteredAnimalIds.push(vaccine.animalIds[j]);
            }
          }
          return (
            <div className="vaccinesListHeaderBorderBottom" key={vaccine._id}>
              <Row>
                <Col className="h100" span={4}>
                  <div className="vaccinesListHeaderBorderRight">
                    {vaccine._id}
                  </div>
                </Col>
                <Col className="h100" span={4}>
                  <div className="vaccinesListHeaderBorderRight">
                    {vaccine.name}
                  </div>
                </Col>
                <Col className="h100" span={4}>
                  <div className="vaccinesListHeaderBorderRight">
                    {filteredAnimalIds.map((animal, i) =>
                      i === 0
                        ? animals[
                            animals.findIndex((item) => item._id === animal)
                          ].name
                        : `, ${
                            animals[
                              animals.findIndex((item) => item._id === animal)
                            ].name
                          }`
                    )}
                  </div>
                </Col>
                <Col className="h100" span={4}>
                  <div className="vaccinesListHeaderBorderRight">
                    {vaccine.duration} {vaccine.duration === 1 ? "an" : "ans"}
                  </div>
                </Col>
                <Col className="h100" span={4}>
                  <div className="vaccinesListHeaderBorderRight">
                    {vaccine.order}
                  </div>
                </Col>
                <Col className="h100" span={4}>
                  <div className="vaccinesListHeaderBorder">
                    <button onClick={() => onSelectVaccine(vaccine._id)}>
                      Modifier
                    </button>
                    <button onClick={() => onShowVaccineDeleteModal(vaccine)}>
                      Supprimer
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Vaccine;
