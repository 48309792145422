import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ToxicDeleteModal from "../toxic-delete-modal/ToxicDeleteModal";
import ToxicModal from "../toxic-modal/ToxicModal";
import "./styles.css";

const ToxicTemplate = () => {
  const { toxics } = useSelector((state) => state.toxicReducer);
  const { toxicCategories } = useSelector(
    (state) => state.toxicCategoryReducer
  );
  const [toggleToxicModal, setToggleToxicModal] = useState({
    active: false,
    toxic: null,
  });
  const [toggleToxicDeleteModal, setToggleToxicDeleteModal] = useState({
    active: false,
    toxic: null,
  });
  const onShowToxicModal = () => {
    setToggleToxicModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const onHideToxicModal = () => {
    setToggleToxicModal((prevState) => ({
      ...prevState,
      toxic: null,
      active: false,
    }));
  };
  const onSelectToxic = (value) => {
    setToggleToxicModal((prevState) => ({
      ...prevState,
      toxic: value,
      active: true,
    }));
  };
  const onShowToxicDeleteModal = (id) => {
    setToggleToxicDeleteModal((prevState) => ({
      ...prevState,
      active: true,
      toxic: id,
    }));
  };
  const onHideToxicDeleteModal = () => {
    setToggleToxicDeleteModal((prevState) => ({
      ...prevState,
      toxic: null,
      active: false,
    }));
  };
  return (
    <div className="toxicContainer">
      {toggleToxicModal.active && (
        <ToxicModal
          toggleModal={toggleToxicModal}
          closeModal={onHideToxicModal}
        />
      )}
      {toggleToxicDeleteModal.active && (
        <ToxicDeleteModal
          toggleModal={toggleToxicDeleteModal}
          closeModal={onHideToxicDeleteModal}
        />
      )}
      <div className="my20">
        <Button onClick={onShowToxicModal}>Ajouter un aliment toxique</Button>
      </div>
      <div className="toxicsList">
        <div className="toxicsListHeader">
          <Row>
            <Col className="h100" span={2}>
              <div className="toxicsListHeaderBorderRight">Image</div>
            </Col>
            <Col className="h100" span={3}>
              <div className="toxicsListHeaderBorderRight">
                Catégorie parent
              </div>
            </Col>
            <Col className="h100" span={2}>
              <div className="toxicsListHeaderBorderRight">Nom</div>
            </Col>
            <Col className="h100" span={3}>
              <div className="toxicsListHeaderBorderRight">Risques</div>
            </Col>
            <Col className="h100" span={3}>
              <div className="toxicsListHeaderBorderRight">Description</div>
            </Col>
            <Col className="h100" span={3}>
              <div className="toxicsListHeaderBorderRight">Symptôme</div>
            </Col>
            <Col className="h100" span={3}>
              <div className="toxicsListHeaderBorderRight">Conseils</div>
            </Col>
            <Col className="h100" span={2}>
              <div className="toxicsListHeaderBorderRight">Ordre</div>
            </Col>
            <Col className="h100" span={3}>
              <div className="p10">Action</div>
            </Col>
          </Row>
        </div>
        {toxics.map((toxic) => {
          const toxicCategory = toxicCategories.find(
            (item) => item._id === toxic.categoryId
          );
          return toxicCategory ? (
            <div className="toxicsListHeaderBorderBottom" key={toxic._id}>
              <Row>
                <Col className="h100" span={2}>
                  <div className="toxicsListHeaderBorderRight">
                    <img src={toxic.image} alt={toxic.name} />
                  </div>
                </Col>
                <Col className="h100" span={3}>
                  <div className="toxicsListHeaderBorderRight">
                    {toxicCategory.name}
                  </div>
                </Col>
                <Col className="h100" span={2}>
                  <div className="toxicsListHeaderBorderRight">
                    {toxic.name}
                  </div>
                </Col>
                <Col className="h100" span={3}>
                  <div className="toxicsListHeaderBorderRight">
                    {toxic.risk}
                  </div>
                </Col>
                <Col className="h100" span={3}>
                  <div className="toxicsListHeaderBorderRight">
                    {toxic.description}
                  </div>
                </Col>
                <Col className="h100" span={3}>
                  <div className="toxicsListHeaderBorderRight">
                    {toxic.symptom}
                  </div>
                </Col>
                <Col className="h100" span={3}>
                  <div className="toxicsListHeaderBorderRight">
                    {toxic.advice}
                  </div>
                </Col>
                <Col className="h100" span={2}>
                  <div className="toxicsListHeaderBorderRight">
                    {toxic.order}
                  </div>
                </Col>
                <Col className="h100" span={3}>
                  <div className="vaccinesListHeaderBorder">
                    <button onClick={() => onSelectToxic(toxic._id)}>
                      Modifier
                    </button>
                    <button onClick={() => onShowToxicDeleteModal(toxic)}>
                      Supprimer
                    </button>
                  </div>
                </Col>
              </Row>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};

export default ToxicTemplate;
