import { Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteUser } from "../../redux/actions/userActions";
import "./styles.css";

function UserDeleteModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();

  const onDeleteUser = () => {
    deleteUser(token, toggleModal.user._id, closeModal, dispatch);
  };

  return (
    <Modal
      title={`Supprimer l'user ${toggleModal.user.name}`}
      open={true}
      onCancel={closeModal}
      footer={() => null}
      className="category_modal_container"
    >
      <div>
        <button onClick={onDeleteUser}>Oui</button>
        <button onClick={closeModal}>Non</button>
      </div>
    </Modal>
  );
}

export default UserDeleteModal;
