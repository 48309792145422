import { Button, Form, Input } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { loginAdmin } from "../../redux/actions/adminActions";
import "./styles.css";

const Login = () => {
  const dispatch = useDispatch();
  const onFinish = (values) => {
    console.log(values);
    loginAdmin(values, dispatch);
  };

  return (
    <div className="loginContainer">
      <Form
        name="basic"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        className="loginForm"
      >
        <Form.Item
          label="Adresse Email"
          name="email"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Mot de passe"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Se connecter
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
