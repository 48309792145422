import { Button, Form, Input, InputNumber, Modal, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToxicImage } from "../../redux/actions/toxicActions";
import {
  addToxicCategory,
  getToxicCategory,
  updateToxicCategory,
} from "../../redux/actions/toxicCategoryActions";
import "./styles.css";

function ToxicCategoryModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (toggleModal.toxicCategory) {
      getToxicCategory(token, toggleModal.toxicCategory)
        .then((res) => {
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            name: res.data.name,
            order: res.data.order,
            active: res.data.active,
            image: res.data.image,
          };
          setImage(res.data.image);
          form.setFieldsValue(fields);
        })
        .catch((err) => console.log(err));
    }
  }, [toggleModal, form, token]);

  const onAddCategory = (body) => {
    console.log(toggleModal.toxicCategory);
    if (toggleModal.toxicCategory) {
      updateToxicCategory(
        token,
        toggleModal.toxicCategory,
        body,
        closeModal,
        dispatch
      );
    } else {
      console.log(body);
      addToxicCategory(token, body, closeModal, dispatch);
    }
  };

  const onChangeImage = (data) => {
    const image = data.target.files[0];
    const formData = new FormData();
    formData.append("image", image);
    addToxicImage(token, formData).then((res) => {
      console.log(res.data);
      let fields = form.getFieldsValue();
      fields = {
        ...fields,
        image: res.data,
      };
      form.setFieldsValue(fields);
      setImage(res.data);
    });
  };

  return (
    <Modal
      title={
        toggleModal.toxicCategory
          ? "Modifier la catégorie de l'aliment toxique"
          : "Ajouter une catégorie d'aliment toxique"
      }
      open={true}
      onCancel={closeModal}
      footer={() => null}
      className="category_modal_container"
    >
      <Form
        layout="vertical"
        onFinish={onAddCategory}
        form={form}
        initialValues={{
          image: "",
          name: "",
          order: "",
          active: true,
        }}
      >
        <Form.Item
          label="Photo"
          name="image"
          rules={[
            {
              required: true,
              message: "Veuillez ajouter l'image principale de la catégorie!",
            },
          ]}
        >
          <div>
            <input
              className="product_form_input_none"
              onChange={(e) => onChangeImage(e)}
              type="file"
              id="new_category_image"
              name="new_category_image"
            />
            <label htmlFor="new_category_image">
              {image ? (
                <img
                  src={image}
                  alt="preview logo"
                  className="product_form_image"
                />
              ) : (
                <div className="product_form_image">+</div>
              )}
            </label>
          </div>
        </Form.Item>
        <Form.Item
          label="Nom de la catégorie de l'aliment toxique"
          name="name"
          rules={[
            {
              required: true,
              message:
                "Veuillez ajouter le nom de la catégorie de l'aliment toxique!",
            },
          ]}
        >
          <Input placeholder="Aliments & produits" />
        </Form.Item>
        <Form.Item label="Ordre d'affichage" name="order">
          <InputNumber placeholder="1" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item label="Active" name="active">
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default ToxicCategoryModal;
