import {
  ADD_BRAND_FAILLED,
  ADD_BRAND_REQUEST,
  ADD_BRAND_SUCCESS,
  DELETE_BRAND_FAILLED,
  DELETE_BRAND_REQUEST,
  DELETE_BRAND_SUCCESS,
  GET_BRANDS_FAILLED,
  GET_BRANDS_REQUEST,
  GET_BRANDS_SUCCESS,
  UPDATE_BRAND_FAILLED,
  UPDATE_BRAND_REQUEST,
  UPDATE_BRAND_SUCCESS,
} from "../types/types";

const initialeState = {
  brands: [],
  brandsLoading: false,
  brandUpdateLoading: false,
};

const brandReducer = (state = initialeState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_BRANDS_REQUEST:
      return {
        ...state,
        brandsLoading: true,
      };
    case GET_BRANDS_SUCCESS:
      return {
        ...state,
        brands: payload,
        brandsLoading: false,
      };
    case GET_BRANDS_FAILLED:
      return {
        ...state,
        brandsLoading: false,
      };
    case ADD_BRAND_REQUEST:
      return {
        ...state,
        brandUpdateLoading: true,
      };
    case ADD_BRAND_SUCCESS:
      return {
        ...state,
        brandUpdateLoading: false,
      };
    case ADD_BRAND_FAILLED:
      return {
        ...state,
        brandUpdateLoading: false,
      };
    case UPDATE_BRAND_REQUEST:
      return {
        ...state,
        brandUpdateLoading: true,
      };
    case UPDATE_BRAND_SUCCESS:
      return {
        ...state,
        brandUpdateLoading: false,
      };
    case UPDATE_BRAND_FAILLED:
      return {
        ...state,
        brandUpdateLoading: false,
      };
    case DELETE_BRAND_REQUEST:
      return {
        ...state,
        brandUpdateLoading: true,
      };
    case DELETE_BRAND_SUCCESS:
      return {
        ...state,
        brandUpdateLoading: false,
      };
    case DELETE_BRAND_FAILLED:
      return {
        ...state,
        brandUpdateLoading: false,
      };
    default:
      return state;
  }
};

export default brandReducer;
