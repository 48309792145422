const api = process.env.REACT_APP_API_URL;

export const LOGIN_ADMIN_URL = `${api}/admin/login-admin`;
export const GET_ADMIN_URL = `${api}/admin/get-admin`;

export const USERS_URL = `${api}/admin/users`;
export const USER_IMAGE_URL = `${api}/admin/user-image`;

export const ANIMALS_URL = `${api}/admin/animals`;
export const ANIMAL_IMAGE_URL = `${api}/admin/animal-image`;

export const BREEDS_URL = `${api}/admin/breeds`;

export const VACCINES_URL = `${api}/admin/vaccines`;

export const DIETS_URL = `${api}/admin/diets`;

export const BRANDS_URL = `${api}/admin/brands`;

export const TOXIC_CATEGORIES_URL = `${api}/admin/toxic-categories`;

export const TOXICS_URL = `${api}/admin/toxics`;
export const TOXIC_IMAGE_URL = `${api}/admin/toxic-image`;

export const PETS_URL = `${api}/admin/pets`;
export const PET_IMAGE_URL = `${api}/admin/pet-image`;

export const HEALTH_BOOKS_URL = `${api}/admin/health-books`;
