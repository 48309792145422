import { Button, Form, Input, InputNumber, Modal, Select, Switch } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addBreed,
  getBreed,
  updateBreed,
} from "../../redux/actions/breedActions";
import "./styles.css";
const { Option } = Select;

function BreedModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { animals } = useSelector((state) => state.animalReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  useEffect(() => {
    console.log(toggleModal);
    if (toggleModal.breed) {
      getBreed(dispatch, toggleModal.breed)
        .then((res) => {
          let fields = form.getFieldsValue();
          fields = {
            ...fields,
            name: res.data.name,
            order: res.data.order,
            active: res.data.active,
          };
          form.setFieldsValue(fields);
        })
        .catch((err) => console.log(err));
    }
  }, [toggleModal, form, dispatch]);

  const onAddCategory = (body) => {
    if (toggleModal.breed) {
      updateBreed(toggleModal.breed, body, closeModal, dispatch);
    } else {
      console.log(body);
      addBreed(token, body, closeModal, dispatch);
    }
  };

  return (
    <Modal
      title={toggleModal.breed ? "Modifier la race" : "Ajouter une race"}
      open={true}
      onCancel={closeModal}
      footer={() => null}
      className="category_modal_container"
    >
      <Form
        layout="vertical"
        onFinish={onAddCategory}
        form={form}
        initialValues={{
          name: "",
          order: "",
          animalId: "",
          active: true,
        }}
      >
        <Form.Item
          label="Nom de la race"
          name="name"
          rules={[
            {
              required: true,
              message: "Veuillez ajouter le nom de la race!",
            },
          ]}
        >
          <Input placeholder="Siamois" />
        </Form.Item>
        <Form.Item label="Catégorie de l'animal" name="animalId">
          <Select>
            {animals.map((animal) => (
              <Option value={animal._id} key={animal._id}>
                {animal.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Ordre d'affichage" name="order">
          <InputNumber placeholder="1" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item label="Active" name="active">
          <Switch />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Enregister
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default BreedModal;
