import {
  ADD_ANIMAL_FAILLED,
  ADD_ANIMAL_REQUEST,
  ADD_ANIMAL_SUCCESS,
  DELETE_ANIMAL_FAILLED,
  DELETE_ANIMAL_REQUEST,
  DELETE_ANIMAL_SUCCESS,
  GET_ANIMALS_FAILLED,
  GET_ANIMALS_REQUEST,
  GET_ANIMALS_SUCCESS,
  UPDATE_ANIMAL_FAILLED,
  UPDATE_ANIMAL_REQUEST,
  UPDATE_ANIMAL_SUCCESS,
} from "../types/types";

const initialeState = {
  animals: [],
  animalsLoading: false,
  animalUpdateLoading: false,
};

const animalReducer = (state = initialeState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ANIMALS_REQUEST:
      return {
        ...state,
        animalsLoading: true,
      };
    case GET_ANIMALS_SUCCESS:
      return {
        ...state,
        animals: payload,
        animalsLoading: false,
      };
    case GET_ANIMALS_FAILLED:
      return {
        ...state,
        animalsLoading: false,
      };
    case ADD_ANIMAL_REQUEST:
      return {
        ...state,
        animalUpdateLoading: true,
      };
    case ADD_ANIMAL_SUCCESS:
      return {
        ...state,
        animalUpdateLoading: false,
      };
    case ADD_ANIMAL_FAILLED:
      return {
        ...state,
        animalUpdateLoading: false,
      };
    case UPDATE_ANIMAL_REQUEST:
      return {
        ...state,
        animalUpdateLoading: true,
      };
    case UPDATE_ANIMAL_SUCCESS:
      return {
        ...state,
        animalUpdateLoading: false,
      };
    case UPDATE_ANIMAL_FAILLED:
      return {
        ...state,
        animalUpdateLoading: false,
      };
    case DELETE_ANIMAL_REQUEST:
      return {
        ...state,
        animalUpdateLoading: true,
      };
    case DELETE_ANIMAL_SUCCESS:
      return {
        ...state,
        animalUpdateLoading: false,
      };
    case DELETE_ANIMAL_FAILLED:
      return {
        ...state,
        animalUpdateLoading: false,
      };
    default:
      return state;
  }
};

export default animalReducer;
