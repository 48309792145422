import { Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteBreed } from "../../redux/actions/breedActions";
import "./styles.css";

function BreedDeleteModal(props) {
  const { token } = useSelector((state) => state.adminReducer);
  const { closeModal, toggleModal } = props;
  const dispatch = useDispatch();

  const onDeleteBreed = () => {
    deleteBreed(token, toggleModal.breed._id, closeModal, dispatch);
  };

  return (
    <Modal
      title={`Supprimer la race ${toggleModal.breed.name}`}
      open={true}
      onCancel={closeModal}
      footer={() => null}
      className="category_modal_container"
    >
      <div>
        <button onClick={onDeleteBreed}>Oui</button>
        <button onClick={closeModal}>Non</button>
      </div>
    </Modal>
  );
}

export default BreedDeleteModal;
