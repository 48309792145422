import axios from "axios";
import { toast } from "react-toastify";
import {
  ADD_ANIMAL_FAILLED,
  ADD_ANIMAL_REQUEST,
  ADD_ANIMAL_SUCCESS,
  DELETE_ANIMAL_FAILLED,
  DELETE_ANIMAL_REQUEST,
  DELETE_ANIMAL_SUCCESS,
  GET_ANIMALS_FAILLED,
  GET_ANIMALS_REQUEST,
  GET_ANIMALS_SUCCESS,
  UPDATE_ANIMAL_FAILLED,
  UPDATE_ANIMAL_REQUEST,
  UPDATE_ANIMAL_SUCCESS,
} from "../types/types";
import { ANIMALS_URL, ANIMAL_IMAGE_URL } from "../url/url";

export const getAnimals = (token, dispatch) => {
  dispatch({
    type: GET_ANIMALS_REQUEST,
  });
  axios
    .get(ANIMALS_URL, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: GET_ANIMALS_SUCCESS,
        payload: res.data,
      });
    })
    .catch(() => {
      dispatch({
        type: GET_ANIMALS_FAILLED,
      });
    });
};

export const getAnimal = (token, id) => {
  return axios.get(`${ANIMALS_URL}/${id}`, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addAnimalImage = (token, body) => {
  return axios.post(ANIMAL_IMAGE_URL, body, {
    headers: {
      "auth-token": token,
    },
  });
};

export const addAnimal = (token, body, closeModal, dispatch) => {
  dispatch({
    type: ADD_ANIMAL_REQUEST,
  });
  axios
    .post(ANIMALS_URL, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: ADD_ANIMAL_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getAnimals(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: ADD_ANIMAL_FAILLED,
      });
    });
};

export const updateAnimal = (token, id, body, dispatch) => {
  dispatch({
    type: UPDATE_ANIMAL_REQUEST,
  });
  axios
    .post(`${ANIMALS_URL}/${id}`, body, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: UPDATE_ANIMAL_SUCCESS,
      });
      toast(res.data.message, { type: "success" });
      getAnimals(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: UPDATE_ANIMAL_FAILLED,
      });
    });
};

export const deleteAnimal = (token, id, closeModal, dispatch) => {
  dispatch({
    type: DELETE_ANIMAL_REQUEST,
  });
  axios
    .delete(`${ANIMALS_URL}/${id}`, {
      headers: {
        "auth-token": token,
      },
    })
    .then((res) => {
      dispatch({
        type: DELETE_ANIMAL_SUCCESS,
      });
      closeModal();
      toast(res.data.message, { type: "success" });
      getAnimals(token, dispatch);
    })
    .catch((err) => {
      toast(err.response.data.message, { type: "error" });
      dispatch({
        type: DELETE_ANIMAL_FAILLED,
      });
    });
};
