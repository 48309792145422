import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Header } from "../layout";
import {
  Animal,
  Breed,
  Diet,
  Home,
  Login,
  Toxic,
  User,
  Vaccine,
} from "../pages";
import { getAdmin } from "../redux/actions/adminActions";
import { getAnimals } from "../redux/actions/animalActions";
import { getBrands } from "../redux/actions/brandActions";
import { getBreeds } from "../redux/actions/breedActions";
import { getDiets } from "../redux/actions/dietActions";
import { getToxics } from "../redux/actions/toxicActions";
import { getToxicCategories } from "../redux/actions/toxicCategoryActions";
import { getUsers } from "../redux/actions/userActions";
import { getVaccines } from "../redux/actions/vaccineActions";
import "./styles.css";

const Navigation = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.adminReducer);
  useEffect(() => {
    if (token) {
      getAnimals(token, dispatch);
      getBreeds(token, dispatch);
      getVaccines(token, dispatch);
      getDiets(token, dispatch);
      getBrands(token, dispatch);
      getToxicCategories(token, dispatch);
      getToxics(token, dispatch);
      getUsers(token, dispatch);
    }
  }, [token, dispatch]);

  useEffect(() => {
    const getToken = async () => {
      const token = await localStorage.getItem("token");
      const body = { token };
      if (token) {
        getAdmin(body, dispatch);
      }
    };
    getToken();
  }, [dispatch]);
  return (
    <BrowserRouter>
      {token && <Header />}
      <main>
        {token ? (
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/users" element={<User />} />
            <Route exact path="/animals" element={<Animal />} />
            <Route exact path="/breeds" element={<Breed />} />
            <Route exact path="/vaccines" element={<Vaccine />} />
            <Route exact path="/diets" element={<Diet />} />
            <Route exact path="/toxics-food" element={<Toxic />} />
            <Route exact path="/*" element={<Navigate replace to="/" />} />
          </Routes>
        ) : (
          <Routes>
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/*" element={<Navigate replace to="/login" />} />
          </Routes>
        )}
      </main>
    </BrowserRouter>
  );
};

export default Navigation;
