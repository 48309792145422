import {
  ADD_TOXIC_FAILLED,
  ADD_TOXIC_REQUEST,
  ADD_TOXIC_SUCCESS,
  DELETE_TOXIC_FAILLED,
  DELETE_TOXIC_REQUEST,
  DELETE_TOXIC_SUCCESS,
  GET_TOXICS_FAILLED,
  GET_TOXICS_REQUEST,
  GET_TOXICS_SUCCESS,
  UPDATE_TOXIC_FAILLED,
  UPDATE_TOXIC_REQUEST,
  UPDATE_TOXIC_SUCCESS,
} from "../types/types";

const initialeState = {
  toxics: [],
  toxicsLoading: false,
  toxicUpdateLoading: false,
};

const toxicReducer = (state = initialeState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TOXICS_REQUEST:
      return {
        ...state,
        toxicsLoading: true,
      };
    case GET_TOXICS_SUCCESS:
      return {
        ...state,
        toxics: payload,
        toxicsLoading: false,
      };
    case GET_TOXICS_FAILLED:
      return {
        ...state,
        toxicsLoading: false,
      };
    case ADD_TOXIC_REQUEST:
      return {
        ...state,
        toxicUpdateLoading: true,
      };
    case ADD_TOXIC_SUCCESS:
      return {
        ...state,
        toxicUpdateLoading: false,
      };
    case ADD_TOXIC_FAILLED:
      return {
        ...state,
        toxicUpdateLoading: false,
      };
    case UPDATE_TOXIC_REQUEST:
      return {
        ...state,
        toxicUpdateLoading: true,
      };
    case UPDATE_TOXIC_SUCCESS:
      return {
        ...state,
        toxicUpdateLoading: false,
      };
    case UPDATE_TOXIC_FAILLED:
      return {
        ...state,
        toxicUpdateLoading: false,
      };
    case DELETE_TOXIC_REQUEST:
      return {
        ...state,
        toxicUpdateLoading: true,
      };
    case DELETE_TOXIC_SUCCESS:
      return {
        ...state,
        toxicUpdateLoading: false,
      };
    case DELETE_TOXIC_FAILLED:
      return {
        ...state,
        toxicUpdateLoading: false,
      };
    default:
      return state;
  }
};

export default toxicReducer;
