import React from "react";
import { BrandTemplate, DietTemplate } from "../../components";

const Diet = () => {
  return (
    <div>
      <DietTemplate />
      <BrandTemplate />
    </div>
  );
};

export default Diet;
