import { Button, Col, Row } from "antd";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { AnimalDeleteModal, AnimalModal } from "../../components";
import "./styles.css";

const Animal = () => {
  const { animals } = useSelector((state) => state.animalReducer);
  const [toggleAnimalModal, setToggleAnimalModal] = useState({
    active: false,
    animal: null,
  });
  const [toggleAnimalDeleteModal, setToggleAnimalDeleteModal] = useState({
    active: false,
    animal: null,
  });
  const onShowAnimalModal = () => {
    setToggleAnimalModal((prevState) => ({
      ...prevState,
      active: true,
    }));
  };
  const onHideAnimalModal = () => {
    setToggleAnimalModal((prevState) => ({
      ...prevState,
      animal: null,
      active: false,
    }));
  };
  const onSelectAnimal = (value) => {
    setToggleAnimalModal((prevState) => ({
      ...prevState,
      animal: value,
      active: true,
    }));
  };
  const onShowAnimalDeleteModal = (id) => {
    setToggleAnimalDeleteModal((prevState) => ({
      ...prevState,
      active: true,
      animal: id,
    }));
  };
  const onHideAnimalDeleteModal = () => {
    setToggleAnimalDeleteModal((prevState) => ({
      ...prevState,
      animal: null,
      active: false,
    }));
  };
  return (
    <div className="animalContainer">
      {toggleAnimalModal.active && (
        <AnimalModal
          toggleModal={toggleAnimalModal}
          closeModal={onHideAnimalModal}
        />
      )}
      {toggleAnimalDeleteModal.active && (
        <AnimalDeleteModal
          toggleModal={toggleAnimalDeleteModal}
          closeModal={onHideAnimalDeleteModal}
        />
      )}
      <div className="my20">
        <Button onClick={onShowAnimalModal}>Ajouter un animal</Button>
      </div>
      <div className="animalsList">
        <div className="animalsListHeader">
          <Row>
            <Col className="h100" span={4}>
              <div className="animalsListHeaderBorderRight">Image</div>
            </Col>
            <Col className="h100" span={5}>
              <div className="animalsListHeaderBorderRight">Id</div>
            </Col>
            <Col className="h100" span={5}>
              <div className="animalsListHeaderBorderRight">Nom</div>
            </Col>
            <Col className="h100" span={5}>
              <div className="animalsListHeaderBorderRight">Ordre</div>
            </Col>
            <Col className="h100" span={5}>
              <div className="p10">Action</div>
            </Col>
          </Row>
        </div>
        {animals.map((animal) => (
          <div className="animalsListHeaderBorderBottom" key={animal._id}>
            <Row>
              <Col className="h100" span={4}>
                <div className="animalsListHeaderBorderRight">
                  <img src={animal.image} alt={animal.name} />
                </div>
              </Col>
              <Col className="h100" span={5}>
                <div className="animalsListHeaderBorderRight">{animal._id}</div>
              </Col>
              <Col className="h100" span={5}>
                <div className="animalsListHeaderBorderRight">
                  {animal.name}
                </div>
              </Col>
              <Col className="h100" span={5}>
                <div className="animalsListHeaderBorderRight">
                  {animal.order}
                </div>
              </Col>
              <Col className="h100" span={4}>
                <div className="vaccinesListHeaderBorder">
                  <button onClick={() => onSelectAnimal(animal._id)}>
                    Modifier
                  </button>
                  <button onClick={() => onShowAnimalDeleteModal(animal)}>
                    Supprimer
                  </button>
                </div>
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Animal;
